import classnames from "classnames";
import { TextField, Typography, FormLabel, FormHelperText } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { errorTestIds } from "../../../../util/classes";
import inputClasses, { inputTestIds } from "./classes";
import "./index.css";

const Input = ({
  name,
  label,
  value = "",
  type = "text",
  inputMode = "text",
  onChange = () => {},
  onBlur = () => {},
  placeholder = "",
  disabled = false,
  required = false,
  multiline = false,
  multilineLines = 4,
  decimalPlaces = 2,
  error = false,
  errorText = "Required.",
  className = "",
  bottomText = "",
  prefix,
  min,
  max,
  step = 1,
  endAdornment,
  "data-testid": testId = ""
}) => {
  const minMaxValue = (input) => {
    const { value } = input;
    if (!value) {
      return true;
    } else {
      const isAboveMin = min === undefined || value >= min;
      const isBelowMax = max === undefined || value <= max;
      return isAboveMin && isBelowMax;
    }
  };

  return (
    <div
      className={classnames(inputClasses.input, { disabled, multiline, error }, className, type, inputMode)}
      data-testid={testId || inputTestIds.input}
      aria-disabled={disabled}
      aria-required={required}
      aria-invalid={error}
    >
      {label && (
        <FormLabel
          component="span"
          className={inputClasses.inputLabel}
          data-testid={inputTestIds.inputLabel}
          required={required}
          disabled={disabled}
          error={error}
          sx={{ mb: 1 }}
        >
          {label}
        </FormLabel>
      )}
      {type === "text" && (
        <TextField
          id={testId}
          type={type}
          name={name}
          style={{ width: "100%" }}
          aria-label="label-project"
          multiline={multiline}
          rows={multiline ? multilineLines : undefined}
          required={required}
          error={error}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          data-cy={testId || inputTestIds.input}
          inputProps={{ type, inputMode, min, max, step }}
          InputProps={{
            endAdornment
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      {type === "number" && (
        <NumericFormat
          id={testId}
          style={{ width: "100%" }}
          name={name}
          value={value}
          prefix={prefix}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          isAllowed={minMaxValue}
          thousandSeparator=","
          fixedDecimalScale
          decimalScale={inputMode === "decimal" ? decimalPlaces : 0}
          placeholder={placeholder}
          customInput={TextField}
          data-cy={testId || inputTestIds.input}
          onValueChange={({ value }) => onChange(value ? +value : null)}
          onBlur={onBlur}
          required={required}
        />
      )}
      {bottomText && (
        <Typography
          variant="subtitle1"
          component="span"
          className={inputClasses.bottomText}
          data-testid={inputTestIds.bottomText}
          sx={{ mb: 1 }}
        >
          {bottomText}
        </Typography>
      )}
      {error && (
        <FormHelperText error sx={{ ml: 2 }} data-testid={`${testId}-${errorTestIds.errorText}`}>
          {errorText}
        </FormHelperText>
      )}
    </div>
  );
};

export default Input;
