import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import {} from './components/Common'; // init, or providers (which import from it) break!
import { Layout } from './components/Layout';
import * as Pages from './pages';
import { NotificationsProvider } from './providers/Notifications';
import { TooltipProvider } from './providers/Tooltips';
import { Provider as AuthProvider } from './providers/AuthProvider';
import { Provider as OfficeProvider } from './providers/OfficeProvider';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from './themes/light';
import { AuthRoutes } from './routes/AuthRoutes'

const App = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={lightTheme}>
        <NotificationsProvider>
          <TooltipProvider>
            <AuthProvider
              renderSignIn={() => (
                <Layout>
                  <Pages.LoginPage />
                </Layout>
              )}
            >
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Pages.SelectOfficePage />} />
                  <Route path="notfound" element={<Pages.NotFoundPage />} status={404}/>
                  <Route path=":officeId" element={<OfficeProvider/>} >
                    {[
                      ...AuthRoutes,
                      <Route path="accessdenied" element={<Pages.AccessDeniedPage />}/>
                    ]}
                    <Route path="*/*" element={<Navigate to="/notfound" replace />}/>
                  </Route>
                  <Route path="*" element={<Navigate to="/notfound" replace />} />
                </Route>
              </Routes>
            </AuthProvider>
          </TooltipProvider>
        </NotificationsProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
