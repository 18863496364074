import { useContext } from 'react';
import { DateTime } from 'luxon';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useUser,
  useOffice,
  useCategories,
  useEventSubscription
} from '../../hooks';
import styled from 'styled-components';
import EventImage from './EventImage';
import EventDetails from './EventDetails';
import EventCardButtons from './EventCardButtons';
import { routes } from '../../util/routes.js';
import { UserRoles } from '../../constants';
import { NotificationsContext } from '../../providers/Notifications';

const CardContainer = styled.div`
  container-name: eventCard;
  container-type: inline-size;
`;

const CardInner = styled.div`
  display: grid;
  grid-template-rows: 15rem auto;
  grid-template-columns: auto;

  @container eventCard (min-width: 600px) {
    grid-template-rows: auto;
    grid-template-columns: 19rem auto;
    max-height: 300px;
  }
`;

const ImageContainer = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: grey;

  @container eventCard (min-width: 600px) {
    grid-column: 1 / 2;
  }
`;

const EventDetailsContainer = styled.div`
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 1.25rem 1rem;

  @container eventCard (min-width: 600px) {
    grid-row: 1 / -1;
    grid-column: 2 / -1;
    padding: 1.25rem 1.875rem;
  }
`;

const validateEvent = (endDate, timezone) => {
  return DateTime.fromISO(endDate).setZone(timezone) > DateTime.local();
};

const canEditEvent = (officeId, loggedInuser, eventCreatorId) => {
  const { id, role, officeId: userOfficeId } = loggedInuser;
  switch (role) {
    case UserRoles.SuperAdmin:
      return true;
    case UserRoles.Admin:
      return officeId === userOfficeId;
    default:
      return officeId === userOfficeId && eventCreatorId === id;
  }
}

const Event = ({ 
  data, 
  enableButtons, 
  onSubscribedToEvent = () => {}, 
  onUnsubscribedFromEvent = () => {} 
}) => {
  const {
    starts,
    ends,
    allDay = true,
    description,
    hosts = '',
    organisation = '',
    title,
    location = '',
    virtualUrl = '',
    id: eventId,
    creatorId,
    subscribers = [],
    needsApproval,
    hasApproval,
    category = '',
  } = data;

  const office = useOffice();

  const navigate = useNavigate();

  const { officeId } = useParams();

  const user = useUser();

  const { subscribeEvent, unsubscribeEvent } = useEventSubscription();

  const notifications = useContext(NotificationsContext);

  const { getCategoryByName } = useCategories();

  const isEventActive = validateEvent(ends, office?.timeZone);
  
  const enableEditButtons = 
    enableButtons && 
    canEditEvent(officeId, user, creatorId) && 
    isEventActive;

  const { id: userId } = user;

  const subscribed = subscribers.filter((s) => s === userId).length > 0;
  
  const evId = `event-${eventId}`;
  
  const editHandler = () => navigate(routes.editEvent.getUrl(officeId, eventId));

  const onEventCardClicked = (event) => {
    if (!event.target.matches('button')) {
      navigate(routes.eventDetails.getUrl(officeId, eventId));
    }
  };

  const onSubscribeToEventClicked = async () => {
    if (await subscribeEvent(officeId, eventId, userId)) {
      notifications.enqueue(
        `Subscribed to ${title}.`,
        'An invitation to this event has been sent to you! Please check your mail. See you there!',
      );
      onSubscribedToEvent();
    }
  };

  const onUnsubscribeFromEventClicked = async () => {
    if (await unsubscribeEvent(officeId, eventId, userId)) {
      notifications.enqueue(
        `Unsubscribed from ${title}.`,
        'You will no longer receive updates about this event.',
      );
      onUnsubscribedFromEvent();
    }
  };

  const from = DateTime.fromISO(starts).setZone(office.timeZone);
  const till = DateTime.fromISO(ends).setZone(office.timeZone);
  const when = (() => {
    const longerThanOneDay = till.diff(from, ['years', 'months', 'days', 'hours']).days > 0;
    const dateOpts = { weekday: 'long', month: 'long', day: '2-digit' }
    const dateFrom = from.toLocaleString(dateOpts);
    const timeFrom = from.toLocaleString(DateTime.TIME_24_SIMPLE);
    const dateTill = till.toLocaleString(dateOpts);
    const timeTill = till.toLocaleString(DateTime.TIME_24_SIMPLE);
    if (longerThanOneDay && allDay) {
      return `${dateFrom} to ${dateTill}, All day`;
    }
    if (longerThanOneDay) {
      return `${dateFrom}, ${timeFrom} to ${dateTill}, ${timeTill}`;
    }
    if (allDay) {
      return `${dateFrom}, All day`;
    }
    return `${dateFrom}, ${timeFrom} - ${timeTill}`;
  })();
  const whosep = organisation.length && hosts.length ? ' | ' : '';
  const who = organisation + whosep + hosts;
  const wheresep = location.length && virtualUrl.length ? ' & ' : '';
  const where = (
    <>
      {location + wheresep}
      {virtualUrl && <a href={virtualUrl}>Virtual session</a>}
    </>
  );

  const categoryIcon = getCategoryByName(category)?.icon;
  return (
    <CardContainer 
      data-testid="event-card" 
      onClick={onEventCardClicked} 
      data-cy="event-card">
      <CardInner>
        <ImageContainer>
          <EventImage icon={categoryIcon} />
        </ImageContainer>
        <EventDetailsContainer>
          <EventDetails
            who={who}
            title={title}
            where={where}
            when={when}
            description={description}
            approved={needsApproval && hasApproval}
            finished={!isEventActive}
          />
          <EventCardButtons
            enableButtons={enableButtons}
            enableEditButtons={enableEditButtons}
            evId={evId}
            subscribed={subscribed}
            editHandler={editHandler}
            subscribeHandler={onSubscribeToEventClicked}
            unsubscribeHandler={onUnsubscribeFromEventClicked}
            finished={!isEventActive}
          />
        </EventDetailsContainer>
      </CardInner>
    </CardContainer>
  );
};

export default Event;