export const EventDef = {
  title: 'title',
  external: 'external',
  officeId: 'officeId',
  location: 'location',
  virtual: 'virtual',
  starts: 'starts',
  ends: 'ends',
  allDay: 'allDay',
  multiDay: 'multiDay',
  hosts: 'hosts',
  organisation: 'organisation',
  category: 'category',
  description: 'description',
  hasApproval: 'hasApproval',
};

export const defaultEvent = {
  [EventDef.title]: '',
  [EventDef.external]: false,
  [EventDef.officeId]: '',
  [EventDef.location]: '',
  [EventDef.virtual]: false,
  [EventDef.starts]: null,
  [EventDef.ends]: null,
  [EventDef.allDay]: false,
  [EventDef.multiDay]: false,
  [EventDef.hosts]: [],
  [EventDef.organisation]: '',
  [EventDef.category]: '',
  [EventDef.description]: '',
  [EventDef.hasApproval]: false
};

export const defaultErrors = {
  [EventDef.title]: '',
  [EventDef.officeId]: '',
  [EventDef.location]: '',
  [EventDef.starts]: '',
  [EventDef.ends]: '',
  [EventDef.hosts]: '',
  [EventDef.organisation]: ''
};