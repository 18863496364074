import { Backdrop, CircularProgress } from '@mui/material';

const Spinner = ({ active = false }) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={active}
  >
    <CircularProgress size={80} />
  </Backdrop>
);

export default Spinner;
