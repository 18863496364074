import logo from './logo-white.png';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { Typography, useTheme, Box } from '@mui/material';
import { useOffice, useUser } from '../../hooks';
import OfficeSelectorDialog from '../Page/OfficeSelectorDialogMUI';
import UserMenu from '../Page/UserMenu';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const LogoHomeNav = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const { officeId } = useParams();

  const handleClick = () => navigate(`/${officeId}`);

  const LogoNavContainer = styled.div`
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    & > img {
      max-height: 2.25rem;
    }

    @media (max-width: 900px) {
      & > img {
        max-height: 1.75rem; /* adjust this as required */
      }
      
      .logo-text {
        font-size: 1.6rem; /* adjust this as required */
      }
    }
`;

 return (
    <>
      <LogoNavContainer color={theme.palette.secondary.contrastText}>
        <img alt="BJSS Ltd." src={logo} onClick={handleClick} style={{ cursor: 'pointer' }} />
        <Typography
          variant="h1"
          component="div"
          color={theme.palette.secondary.contrastText}
          className="logo-text"
          onClick={handleClick}
          style={{ cursor: 'pointer' }} 
        >
          Wallboard
        </Typography>
      </LogoNavContainer>
    </>
  );
};
export const OfficeNav = () => {
  const location = useLocation();
  const isWallboardPage = location.pathname.includes('/wallboard');
  const { name: officeName } = useOffice();
  const { name: userName } = useUser();
  const theme = useTheme();
  const OfficeNavContainer = styled.div`
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    border-left: 3px solid ${(props) => props.color};
    @media (max-width: 900px) {
      border-left: none;
      justify-content: flex-start; 
      align-items: center;     
      padding-left: 0rem;
      padding-top: 2rem;
    }
    @media (min-width: 901px) {
      flex-direction: row;
    }
    .office-nav-text {
      @media (max-width: 900px) {
        color: rgb(4, 30, 66);
        font-size: 1.8rem;
      }
    }
`;

  return (
    <>
      <OfficeNavContainer color={theme.palette.secondary.contrastText} >
        <Typography
          variant="h1"
          component="div"
          color={theme.palette.secondary.contrastText}
          className="office-nav-text"
        >
          <Box component="span" sx={{ fontWeight: 500 }}>
            Hello,
          </Box>{' '}
          {isWallboardPage ? officeName : userName}
        </Typography>
        <OfficeSelectorDialog />
      </OfficeNavContainer>
    </>
  );
};

export const Header = ({ loggedInUser }) => {
  const theme = useTheme();
  const headerRef = useRef(null);
  const HeaderContainer = styled.div`
    padding: 1.25rem 6.25rem;
    background-color: ${(props) => props.backgroundcolour};
    border-bottom: 5px solid ${(props) => props.bordercolour};
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 900px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%; 
    }
  `;
  const OfficeNavMobileWrapper = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    padding: 0 20px; 
  }
`;

const OfficeNavDesktopWrapper = styled.div`
@media (max-width: 900px) {
  display: none;
}
`;

const MenuContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;


return (
  <>
    <HeaderContainer
      ref={headerRef}
      backgroundcolour={theme.palette.bjssNavy.main}
      bordercolour={theme.palette.bjssGreen.main}
    >
      <MenuContainer>
        <LogoHomeNav />
        |<OfficeNavDesktopWrapper>
        <OfficeNav />
        </OfficeNavDesktopWrapper>
      </MenuContainer>
      <UserMenu loggedInUser={loggedInUser} headerRef={headerRef} />
    </HeaderContainer>
    <OfficeNavMobileWrapper>
      <OfficeNav />
    </OfficeNavMobileWrapper>
  </>
);
};