import styled from 'styled-components';
import { default as ReactSwitch } from 'react-switch';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.3em;
  gap: 1em;
`;

const Label = styled.label`
  color: black;
  margin: 0.1em 0 0.5em;
  min-height: 1.5em;
`;

export const Switch = (props) => {
  const { onChange, checked, label, dataCy, labelDataCy } = props;

  return (
    <Row>
      <ReactSwitch
        data-cy={dataCy}
        onChange={onChange}
        onColor="#000"
        offColor="#000"
        boxShadow="inset 0 0 0 2px rgba(0, 0, 0, 1)"
        activeBoxShadow="inset 0 0 0 2px rgba(0, 0, 0, 1)"
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={26}
        handleDiameter={30}
      />
      <Label dataCy={labelDataCy}>{label}</Label>
    </Row>
  );
};
