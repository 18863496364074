import styled from 'styled-components';
import {
  CheckCircleIcon,
  ErrorIcon,
  HelpIcon,
  InfoIcon,
  WarningIcon,
} from '../Icons';

const Message = styled.div`
  max-height: 1.5rem;
  opacity: 1;
  overflow: hidden;
  transition: 250ms ease-in-out;

  .inner {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 1.5rem;
    height: 1.55rem;
    margin-right: 0.5rem;
  }

  &.hidden {
    max-height: 0;
    opacity: 0;
  }

  .error {
    fill: #dc3545;
    color: #dc3545;
  }

  .help {
    fill: #0d6efd;
    color: #0d6efd;
  }

  .info {
    fill: #0d6efd;
    color: #0d6efd;
  }

  .success {
    fill: #198754;
    color: #198754;
  }

  .warning {
    fill: #fd7e14;
    color: #fd7e14;
  }
`;

const PopMessage = ({ messageText, hidden = false, type = 'info' }) => {
  return (
    <Message className={`pop-message ${hidden && 'hidden'} ${type}`}>
      <div className="inner">
        {type === 'error' && <ErrorIcon cssClass="error" />}
        {type === 'help' && <HelpIcon cssClass="help" />}
        {type === 'info' && <InfoIcon cssClass="info" />}
        {type === 'success' && <CheckCircleIcon cssClass="success" />}
        {type === 'warning' && <WarningIcon cssClass="warning" />}
        <span className={type}>{messageText}</span>
      </div>
    </Message>
  );
};

export default PopMessage;
