import { LoginPagesLayout } from '../components/Layout';
import { useOffices, useUsers } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { Autocomplete, TextField, Typography, useTheme } from '@mui/material';
import { Button } from '../components/Common';
import { Context } from "../providers/AuthProvider";

const SelectOfficePage = () => {
  const { state: { currentUser } = {} } = useContext(Context);

  const { palette } = useTheme();

  const { getUser, updateUser } = useUsers();

  const navigate = useNavigate();

  const offices = useOffices();

  const [selectedOfficeId, setSelectedOfficeId] = useState(undefined);

  const [loggedInUser, setLoggedInUser] = useState();

  const options = offices.map((office) => office.name);

  const handleChange = (value) => {
    const office = offices.find((office) => office.name === value);
    setSelectedOfficeId(office.id);
  };

  const handleButtonClick = async () => {
    if (!selectedOfficeId) {
      return;
    }
    await updateUser({ ...loggedInUser, officeId: selectedOfficeId });
    navigate(`/${selectedOfficeId}`);
  };

  useEffect(() => {
    const fetchUserOffice = async () => {
      const user = await getUser(currentUser?.uid);
      if (user) {
        setLoggedInUser(user);
        const { officeId } = user;
        if (officeId) {
          navigate(`/${officeId}`);
        }
      }
    }
    fetchUserOffice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uid, navigate]);

  return (
    <LoginPagesLayout>
      <Typography
        variant="h3"
        align="center"
        sx={{ mb: 5, color: palette.bjssWhite.main }}
      >
        Please select your office
      </Typography>

      <Autocomplete
        fullWidth
        disablePortal
        disableClearable
        options={options}
        onChange={(event, newValue) => handleChange(newValue)}
        sx={{
          mb: 3,
          label: {
            color: '#ffffff',
            opacity: 0.5,
            transition: '150ms ease-in-out',
          },
          svg: {
            fill: '#ffffff',
            opacity: 0.5,
            transition: '150ms ease-in-out',
          },
          input: {
            color: '#ffffff',
          },
          '.MuiInput-root::before': {
            borderBottomColor: '#ffffff',
            opacity: 0.5,
            transition: '150ms ease-in-out',
          },
          '& :hover': {
            label: {
              opacity: 1,
            },
            svg: {
              opacity: 1,
            },
            '.MuiInput-root:not(.Mui-disabled, .Mui-error)::before': {
              borderBottom: '1px solid #ffffff',
              opacity: 1,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            color="bjssWhite"
            label="Office"
            variant="standard"
          />
        )}
      />

      <Button
        type="signIn"
        onClick={handleButtonClick}
        dataCy="login-button"
        fullWidth
        disabled={!selectedOfficeId}
      >
        Continue
      </Button>
    </LoginPagesLayout>
  );
};

export default SelectOfficePage;
