const selectClasses = {
  select: "select-field",
  selectLabel: "select-label"
}

export const selectTestIds = {
  select: "select-field",
  selectLabel: "select-label",
  selectOption: "select-option"
}

export default selectClasses;
