import styled from 'styled-components';
import { Modal as MUIModal, Paper, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ModalInner = styled.div`
  container-type: inline-size;
  pointer-events: all;
  width: 100%;
  z-index: 100;

  .MuiPaper-root:focus-visible {
    outline: none;
  }

  @media screen and (min-width: 700px) {
    max-width: 700px;
  }
`;

const ModalContentContainer = styled.div`
  position: relative;
  padding: 1.25rem;
`;

const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 200;

  .MuiSvgIcon-root {
    fill: ${(props) => props.$colour};
    transition: fill 150ms ease-in-out;
  }

  &:hover,
  &:focus-within {
    .MuiSvgIcon-root {
      fill: ${(props) => props.$hovercolour};
    }
  }
`;

export const Modal = ({
  children,
  active = false,
  onClose,
  closeButton = false,
  className,
  'data-testid': testId
}) => {
  const { palette } = useTheme();

  return (
    <MUIModal
      open={active}
      onClose={onClose}
      style={{ display: 'grid', placeItems: 'center', padding: '1rem' }}
      className={className}
      data-testid={testId}
    >
      <ModalInner>
        <Paper elevation={5}>
          <ModalContentContainer>
            {closeButton && (
              <CloseButton
                $colour={palette.bjssGrey.main}
                $hovercolour={palette.bjssNavy.main}
                onClick={onClose}
              >
                <CloseIcon />
              </CloseButton>
            )}
            {children}
          </ModalContentContainer>
        </Paper>
      </ModalInner>
    </MUIModal>
  );
};

// TODO: Remove these sub-components once they are no longer in use:
const Title = styled.h1`
  text-align: center;
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 0.8em;
`;
Modal.Title = Title;

const Footer = styled.div`
  margin-top: 1.5rem;
`;
Modal.Footer = Footer;
