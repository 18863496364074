import { DateTime } from 'luxon';

const formatDateTime = (dateTime) => {
  return typeof starts == 'string' ? 
    DateTime.fromISO(dateTime) 
    : 
    DateTime.fromISO(dateTime.toDate().toISOString());
}

export const decodeEvent = (event, fail) => {
  if (!event) {
    fail('it was not found');
    return;
  }

  const {
    id,
    creator = '',
    creatorId = '',
    title = '',
    location = '',
    virtualUrl = '',
    allDay = false,
    hosts = '',
    organisation = '',
    category = '',
    description = '',
    needsApproval = false,
    hasApproval = false,
    external = false,
    subscribers = [],
    version,
    starts,
    ends
  } = event;

  if (!version || version <= 0) {
    fail('it is in an unrecognised format');
    return;
  }
  if (version > 1) {
    fail('it is an unrecognised (future!) format');
    return;
  }
  if (!starts || !starts.toDate) {
    fail('it contains a corrupted start timestamp');
    return;
  }
  if (!ends || !ends.toDate) {
    fail('it contains a corrupted end timestamp');
    return;
  }
  const fromDateTime = formatDateTime(starts);
  if (!fromDateTime.isValid) {
    fail('it contains an invalid start date/time');
    return;
  }
  const toDateTime = formatDateTime(ends);
  if (!toDateTime.isValid) {
    fail('it contains an invalid end date/time');
    return;
  }

  return {
    id,
    creator,
    creatorId,
    title,
    location,
    virtualUrl,
    starts: fromDateTime,
    ends: toDateTime,
    allDay,
    hosts,
    organisation,
    category,
    description,
    needsApproval,
    hasApproval,
    external,
    subscribers,
  };
}
  
export const encodeEvent = (event) => {
  const {
    creator = '',
    creatorId = '',
    title = '',
    location = '',
    virtualUrl = '',
    allDay = false,
    hosts = '',
    organisation = '',
    category = '',
    description = '',
    needsApproval = false,
    hasApproval = false,
    external = false,
    subscribers = [],
    starts,
    ends
  } = event;

  return {
    version: 1,
    creator,
    creatorId,
    title,
    location,
    virtualUrl,
    starts: new Date(starts.toISO({ suppressMilliseconds: true, suppressSeconds: true })),
    ends: new Date(ends.toISO({ suppressMilliseconds: true, suppressSeconds: true })),
    allDay,
    hosts,
    organisation,
    category,
    description,
    needsApproval,
    hasApproval,
    external,
    subscribers,
  }
};