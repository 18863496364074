import { Button as MUIButton, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from 'styled-components';

const Icon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  svg {
    fill: ${(props) => props.$colour};
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const Button = ({
  children,
  icon = undefined,
  onClick,
  type = 'primary',
  size = 'large',
  colour = undefined,
  elevation = false,
  fullWidth = false,
  disabled = false,
  'data-testid': testId = undefined
}) => {
  const theme = useTheme();

  const icons = {
    add: <AddIcon />,
    check: <CheckIcon />,
    edit: <EditIcon />,
  };

  const PrimaryButton = (
    <MUIButton
      className="button"
      onClick={onClick}
      variant="contained"
      size={size}
      disableElevation={!elevation}
      fullWidth={fullWidth}
      disabled={disabled}
      data-testid={testId}
    >
      {icon && (
        <Icon $colour={theme.palette.primary.contrastText}>{icons[icon]}</Icon>
      )}
      {children}
    </MUIButton>
  );

  const LightButton = (
    <MUIButton
      className="button"
      onClick={onClick}
      variant="contained"
      size={size}
      disableElevation={!elevation}
      fullWidth={fullWidth}
      disabled={disabled}
      data-testid={testId}
      style={{ 
        backgroundColor: theme.palette.lightGreen.main, 
        color: theme.palette.lightGreen.contrastText 
      }}
    >
      {icon && (
        <Icon $colour={theme.palette.lightGreen.contrastText}>
          {icons[icon]}
        </Icon>
      )}
      {children}
    </MUIButton>
  );

  const SecondaryButton = (
    <MUIButton
      className="button"
      onClick={onClick}
      variant="outlined"
      size={size}
      disableElevation={!elevation}
      fullWidth={fullWidth}
      disabled={disabled}
      style={{ color: theme.palette.bjssNavy.main }}
      data-testid={testId}
    >
      {icon && <Icon $colour={theme.palette.bjssNavy.main}>{icons[icon]}</Icon>}
      {children}
    </MUIButton>
  );

  const DangerButton = (
    <MUIButton
      className="button"
      onClick={onClick}
      variant="outlined"
      color="error"
      size={size}
      disableElevation={!elevation}
      fullWidth={fullWidth}
      disabled={disabled}
      data-testid={testId}
    >
      {icon && <Icon $colour={theme.palette.error.main}>{icons[icon]}</Icon>}
      {children}
    </MUIButton>
  );

  const TextButton = (
    <MUIButton
      className="button"
      onClick={onClick}
      variant="text"
      color={colour ? colour : 'secondary'}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      data-testid={testId}
    >
      {icon && <Icon $colour={theme.palette.error.main}>{icons[icon]}</Icon>}
      {children}
    </MUIButton>
  );

  const SignInButton = (
    <MUIButton
      className="button"
      onClick={onClick}
      variant="contained"
      size={size}
      color="bjssPink"
      style={{ color: theme.palette.bjssWhite.main }}
      data-testid={testId}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {icon && (
        <Icon $colour={theme.palette.bjssWhite.main}>{icons[icon]}</Icon>
      )}
      {children}
    </MUIButton>
  );

  switch (type) {
    case 'primary':
      return PrimaryButton;
    case 'light':
      return LightButton;
    case 'secondary':
      return SecondaryButton;
    case 'danger':
      return DangerButton;
    case 'signIn':
      return SignInButton;
    case 'text':
      return TextButton;
    default:
      return PrimaryButton;
  }
};

export default Button;
