import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { red } from "@mui/material/colors";
import classnames from "classnames";
import checkboxClasses, { checkboxTestIds } from "./classes";
import { errorTestIds } from "../../../../util/classes";
import "./index.css";

const CheckboxField = ({
  label,
  labelPlacement = "top",
  name,
  value = false,
  required = false,
  disabled = false,
  error = false,
  errorText = "Required.",
  onChange = () => {},
  className,
  "data-testid": testId
}) => {
  return (
    <div
      className={classnames(checkboxClasses.checkbox, { disabled, error }, className)}
      data-testid={testId || checkboxTestIds.checkbox}
    >
      <FormControlLabel
        disabled={disabled}
        sx={{ mb: 1, fontSize: "14px", color: error ? red[800] : null }}
        className={checkboxClasses.checkboxLabel}
        data-testid={checkboxTestIds.checkboxLabel}
        control={
          <Checkbox
            id={testId}
            name={name}
            className={checkboxClasses.checkboxInput}
            data-testid={checkboxTestIds.checkboxInput}
            checked={value}
            required={required}
            disabled={disabled}
            data-cy={testId || checkboxTestIds.checkbox}
            onChange={(_, checked) => onChange(checked)}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {error && (
        <FormHelperText error sx={{ ml: 2 }} data-testid={`${testId}-${errorTestIds.errorText}`}>
          {errorText}
        </FormHelperText>
      )}
    </div>
  );
};

export default CheckboxField;
