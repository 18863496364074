import styled, { css } from "styled-components";

// Usage:
// const pass = Form.validate(
//   [ setErrorState, 'With this error message if', errorCondition ],
//   [ setErrorState, 'With this error message if', errorCondition ], // ...
// );
const validate = ( ...items ) => {
  // reset error state
  items.map( item => item[0]( null ) );
  // check errors
  return items.reduce( ( acc, item ) => {
    if( item[ 2 ] ) {
      item[ 0 ]( item[ 1 ] );
      return false;
    }
    return acc;
  }, true );
}

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin: 0 0 0.3em 0;
`;
Form.validate = validate;

const Row = styled.div( ({ flex = 1 }) => css`
  flex: ${flex};
  display: flex;
  flex-direction: row;
  gap: 2.5em;
` );
Form.Row = Row;

const FieldContainer = styled.div( ({ flex = 1, $row }) => css`
  display: flex;
  flex-direction: ${ $row? 'row' : 'column' };
  flex: ${flex};

  ${ $row? css`
  & > label {
    width: 20%;
    min-width: 150px;
    padding-top: 0.5em;
  }
  ` : null }
` );

const Label = styled.label`
  color: black;
  margin-bottom: 0.5em;
  min-height: 1.5em;
  font-weight: 600;
  font-size: 0.9em;
`;
Form.Label = Label;

const Field = props => {
  const {
    children,
    label,
    flex = 1,
    row,
    labelDataCy,
  } = props;

  return (
    <FieldContainer flex={flex} $row={row}>
      <Label data-cy={labelDataCy}>{ label }</Label>
      { children }
    </FieldContainer>
  );
};
Form.Field = Field;
