import styled from "styled-components";
import { useContext } from 'react';
import { NotificationsContext } from '../../providers/Notifications';

const NotificationsContainer = styled.div`
  background: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0rem;
  margin: auto;
  min-width: 50%;
  max-width: 900px;
  z-index: 1000;
  padding: 1rem;
`;

const NotificationContainer = styled.div`
  background: #eee;
  padding: 2rem 2rem 2rem 2.5rem;
  border: 2px black solid;
  box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.2);
  button {
    float: right;
    border: 0;
    background: rgba(255, 255, 255, 0);
    padding: 0.6rem 1.3rem;
    margin: -.8rem;
    cursor: pointer;
    color: #888;
  }

  button:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0 0 0.5rem 0;
  text-align: center;
`;

const Message = styled.div`
  text-align: center;
`;

const Notification = ({ notification }) => {
  const api = useContext( NotificationsContext );

  const dismissHandler = () => {
    api.dismiss( notification.id );
  }

  return (
    <NotificationContainer data-cy="notification-card">
      <button onClick={dismissHandler}>X</button>
      <Title data-cy="notification-title">{ notification.title }</Title>
      <Message data-cy="notification-message">{ notification.message }</Message>
    </NotificationContainer>
  );
};

const Notifications = ({ notifications }) => (
  notifications.length > 0 &&
    <NotificationsContainer>
      { notifications.map( (notification, key) =>
        <Notification key={key} notification={notification} />
      ) }
    </NotificationsContainer>
);

export default Notifications;
