const HelpIcon = ({ cssClass }) => {
  return (
    <svg
      className={`icon icon--help ${cssClass}`}
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M484.028-247Q500-247 511-258.028q11-11.028 11-27T510.972-312q-11.028-11-27-11T457-311.972q-11 11.028-11 27T457.028-258q11.028 11 27 11Zm-3.762 167q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Zm2.767-180Q513-660 536-641.5q23 18.5 23 47.196 0 26.304-15.648 45.733Q527.705-529.143 508-512q-23 19-40 42.385-17 23.384-17 52.615 0 11 8.4 17.5T479-393q12 0 19.875-8T509-421q3-21 16-38t30.231-30.784Q580-510 596-537t16-58.614Q612-646 574.5-679.5 537-713 485.548-713 450-713 417-698t-54 44q-7 10-6.5 21.5t9.469 18.5q11.416 8 23.646 5 12.231-3 20.385-14 12.75-17.903 31.875-27.452Q461-660 482.767-660Z" />
    </svg>
  );
};

export default HelpIcon;
