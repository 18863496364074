import styled from 'styled-components';

const PageContainer = styled.div`
  container-type: size;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
`;

const PageContent = styled.div`
  padding: 0 20px;

  @container (min-width: 900px) {
    padding: 0 100px;
  }
`;

export const Page = ({ children }) => {
  return (
    <PageContainer>
      <PageContent>{children}</PageContent>
    </PageContainer>
  );
};
