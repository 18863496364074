import { useEffect, Fragment, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUsers } from '../hooks/useUsers';
import { UserRoles } from '../constants';
import { Context } from "../providers/AuthProvider";

const canAccessAdminPage = (user) => {
  const { role } = user;
  switch (role) {
    case UserRoles.SuperAdmin:
    case UserRoles.Admin:
      return true;
    default:
      return false;
  }
};

const AuthGuardAdminPage = ({component}) => {
  const { state: { currentUser } = {} } = useContext(Context);
  const { getUser } = useUsers();
  const navigate = useNavigate();
  const { officeId } = useParams();

  useEffect(() => {
    const checkUserRole = async () => {
      const user = await getUser(currentUser?.uid);
      if (!canAccessAdminPage(user)) {
        navigate(`/${officeId}/accessdenied`, { replace: true });
      }
    }
    checkUserRole();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, currentUser, navigate, officeId]);

  return <Fragment>{component}</Fragment>
}

export { AuthGuardAdminPage };