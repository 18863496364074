import { useEffect, useState } from "react";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import app from "../integrations/firebase";
import { ErrorHandler } from '../util/errorHandler';

const useOffices = () => {
  const [offices, setOffices] = useState([]);

  const { handleError } = ErrorHandler();

  useEffect(() => {
    const db = getFirestore(app);
    const officesRef = collection(db, "offices");

    const unsubscribe = onSnapshot(
      officesRef, 
      (snapshot) => {
        setOffices(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      },
      (error) => {
        handleError(error, 'unable to fetch offices');
      }
    );

    return () => unsubscribe();
  }, [handleError]);

  return offices;
};

export default useOffices;
