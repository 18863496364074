import { Switch, FormHelperText, Box, Typography, FormControlLabel } from '@mui/material';
import classnames from 'classnames';
import toggleClasses, { toggleTestIds } from './classes';
import { errorTestIds } from '../../../../util/classes';
import { red } from '@mui/material/colors';
import './index.css';

const ToggleField = ({
  label,
  labelPlacement = "top",
  name,
  value = false,
  required = false,
  disabled = false,
  error = false,
  errorText = 'Required.',
  onText = '',
  offText = '',
  onChange = () => {},
  className,
  'data-testid': testId,
}) => {
  return (
    <Box
      className={classnames(toggleClasses.toggle, { disabled, error }, className)}
      data-testid={testId || toggleTestIds.toggle}
    >
      <FormControlLabel
        disabled={disabled}
        sx={{ mb: 1, fontSize: "14px", color: error ? red[800] : null }}
        className={toggleClasses.toggleLabel}
        data-testid={toggleTestIds.toggleLabel}
        control={
          <Box className={toggleClasses.toggleInputContainer}>
            {offText && (
              <Typography
                variant='body1'
                component='div'
                fontSize='16px'
                lineHeight='1.5'
                letterSpacing='0.2px'
                fontFamily='Catamaran'
                fontWeight={400}
                sx={{ mr: 0.5 }}
                className={toggleClasses.toggleOffText}
                data-testid={toggleTestIds.toggleOffText}>
                {offText}
              </Typography>
            )}
            <Switch
              id={testId}
              name={name}
              checked={value}
              disabled={disabled}
              required={required}
              className={toggleClasses.toggleInput}
              data-testid={toggleTestIds.toggleInput}
              data-cy={testId || toggleClasses.toggle}
              onChange={(_, checked) => onChange(checked)}
            />
            {onText && (
              <Typography
                variant='body1'
                component='div'
                fontSize='16px'
                lineHeight='1.5'
                letterSpacing='0.2px'
                fontFamily='Catamaran'
                fontWeight={400}
                sx={{ ml: 0.5 }}
                className={toggleClasses.toggleOnText}
                data-testid={toggleTestIds.toggleOnText}>
                {onText}
              </Typography>
            )}
          </Box>
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {error && (
        <FormHelperText error sx={{ ml: 2 }} data-testid={`${testId}-${errorTestIds.errorText}`}>
          {errorText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default ToggleField;
