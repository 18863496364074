import { useState, useMemo, useCallback } from 'react';
import { Events } from '../components/Events';
import { Page } from '../components/Layout';
import { Button } from '../components/Common';
import { DateTime } from 'luxon';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Fab, Paper, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MobileCalendar from '../components/Page/MobileCalendar';
import Calendar from '../components/Common/Calendar';
import { routes } from '../util/routes.js';

const PageLayout = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 320px;
  }
`;

const PageHeader = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  margin-top: 3.125rem;
  margin-bottom: 1.88rem;
`;

const EventsContainer = styled.main`
  grid-row: 2 / -1;
  grid-column: 1 / 2;

  @media (min-width: 900px) {
    margin-right: 3.125rem;
  }
`;

const CalendarContainer = styled.aside`
  display: none;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    grid-row: 2 / -1;
    grid-column: 2 / -1;
  }
`;

const Floaters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: fixed;
  bottom: 20px;
  right: 10px;

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

const MainPage = () => {
  const [from, setFrom] = useState(DateTime.now().startOf('day'));

  const [filter, setFilter] = useState('day');

  const { officeId } = useParams();

  const navigate = useNavigate();

  const [singleDay, setSingleDay] = useState(true);

  const [showMobileCalendar, setShowMobileCalendar] = useState(false);

  // The new UI design does not have a subscribed events filter, however, I have
  // left this in case this needs to be restored in future. ~ RM
  const [subscribedEvents, setSubscribedEvents] = useState(false);

  const till = useMemo(() => {
    switch (filter) {
      case 'week':
        setSingleDay(false);
        return from.endOf('week');
      case 'month':
        setSingleDay(false);
        return from.endOf('month');
      default:
        setSingleDay(true);
        return from.plus({ days: 1 });
    }
  }, [from, filter]);

  const onFilterChange = useCallback((filter) => setFilter(filter), []);

  const createHandler = () => navigate(routes.createEvent.getUrl(officeId));

  const title = () => {
    const fromDate = DateTime.fromISO(
      new Date(from).toISOString(),
    ).toLocaleString(DateTime.DATE_MED);

    const tillDate = DateTime.fromISO(
      new Date(till).toISOString(),
    ).toLocaleString(DateTime.DATE_MED);

    if (singleDay) return `Events on ${fromDate}`;

    return `Events for ${fromDate} to ${tillDate}`;
  };

  return (
    <>
      <Page>
        <PageLayout>
          <PageHeader>
            <Typography variant="h2">{title()}</Typography>
          </PageHeader>
          <EventsContainer data-cy="events-list">
            <Events
              officeId={officeId}
              showPeriodFrom={from}
              showPeriodTill={till}
              enableButtons
              filterSubscriptions={subscribedEvents}
            />
          </EventsContainer>
          <CalendarContainer>
            <Paper elevation={5}>
              <Calendar
                from={from}
                till={!singleDay ? from + till : undefined}
                onDayChange={(value) => setFrom(value.startOf('day'))}
                onFilterChange={onFilterChange}
              />
            </Paper>
            <Button
              dataCy="create-event-button"
              onClick={createHandler}
              fullWidth
              elevation
            >
              Create event
            </Button>
          </CalendarContainer>
        </PageLayout>
      </Page>

      <Floaters>
        <Fab
          color="secondary"
          size="medium"
          aria-label="create event"
          onClick={createHandler}
        >
          <AddIcon />
        </Fab>
        <Fab
          color="primary"
          size="medium"
          aria-label="select dates"
          onClick={() => setShowMobileCalendar(true)}
        >
          <CalendarMonthIcon />
        </Fab>
      </Floaters>

      <MobileCalendar
        active={showMobileCalendar}
        onClose={() => setShowMobileCalendar()}
      >
        <Calendar
          from={from}
          till={!singleDay ? from + till : undefined}
          onDayChange={(value) => setFrom(value.startOf('day'))}
          onFilterChange={onFilterChange}
          datacyprefix="mobile"
        />
      </MobileCalendar>
    </>
  );
};

export default MainPage;
