import { Route } from 'react-router-dom';
import {} from '../components/Common'; // init, or providers (which import from it) break!
import * as Pages from '../pages';
import { AuthGuard, AuthGuardAdminPage } from '../guards'

const AuthRoutes = [
  <Route key="main" index element={<AuthGuard component={<Pages.MainPage />} />} />,
  <Route key="account" path="account" element={<AuthGuard component={<Pages.ProfilePage />} />} />,
  <Route key="admin" path="admin" element={<AuthGuardAdminPage component={<Pages.AdminPage />} />} />,
  <Route key="wallboard" path="wallboard" element={<AuthGuard component={<Pages.WallboardPage />} />} />,
  <Route key="event-details" path="event/:eventId" element={<AuthGuard component={<Pages.EventDetailsPage />} />} />,
  <Route key="event-create" path="event/create" element={<AuthGuard component={<Pages.CreateEditEventPage />} />} />,
  <Route key="event-edit" path="event/edit/:eventId" element={<AuthGuard component={<Pages.CreateEditEventPage edit />} />} />,
]

export { AuthRoutes };