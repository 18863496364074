export const routes = {
  home: {
    getUrl: () => `/`,
    path: "/"
  },
  notFound: {
    getUrl: () => `/notfound`,
    path: "notfound"
  },
  office: {
    getUrl: (officeId) => `/${officeId}`,
    path: ":officeId"
  },
  account: {
    getUrl: (officeId) => `/${officeId}/account`,
    path: "account"
  },
  wallboard: {
    getUrl: (officeId) => `/${officeId}/wallboard`,
    path: "wallboard"
  },
  eventDetails: {
    getUrl: (officeId, eventId) => `/${officeId}/event/${eventId}`,
    path: "event/:eventId"
  },
  createEvent: {
    getUrl: (officeId) => `/${officeId}/event/create`,
    path: "event/create"
  },
  editEvent: {
    getUrl: (officeId, eventId) => `/${officeId}/event/edit/${eventId}`,
    path: "event/edit/:eventId"
  }
};
