import { useState } from 'react';
import classnames from 'classnames';
import { Autocomplete, TextField, FormLabel, FormHelperText, Tooltip } from '@mui/material';
import selectClasses, { selectTestIds } from './classes';
import { errorTestIds } from '../../../../util/classes';
import './index.css';

const Select = ({
  name,
  label,
  value,
  onChange = () => {
  },
  placeholder = '',
  options = [],
  required = false,
  disabled = false,
  error = false,
  errorText = 'Required.',
  className = '',
  tooltip = '',
  'data-testid': testId = '',
}) => {
  const [autoCompleteValue, setAutoCompleteValue] = useState('');
  const selectedOption = options.find((option) => option.id === value);

  return (
    <div
      className={classnames(selectClasses.select, { disabled, error }, className)}
      data-testid={testId || selectTestIds.select}
      aria-disabled={disabled}
      aria-required={required}
      aria-invalid={error}
    >
      {label && (
        <FormLabel
          component='span'
          className={selectClasses.selectLabel}
          data-testid={selectTestIds.selectLabel}
          required={required}
          disabled={disabled}
          error={error}
          sx={{ mb: 1 }}
        >
          {label}
        </FormLabel>
      )}
      <Autocomplete
        id={testId}
        style={{ width: '100%' }}
        aria-label={label}
        options={options}
        value={selectedOption || null}
        inputValue={selectedOption ? selectedOption.label : autoCompleteValue}
        disabled={disabled}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.id}
            id={`${testId}-${option.label}`}
            data-testid={`${selectTestIds.selectOption}-${option.id}-${option.label}`}
          >
            {option.label}
          </li>
        )}
        getOptionLabel={(option) => `${option.label}`}
        onChange={(event, value) => {
          onChange(value && value.id);
          setAutoCompleteValue('');
        }}
        onInputChange={(event, value) => {
          setAutoCompleteValue(value);
        }}
        isOptionEqualToValue={(option, value) => !value || option.id === value.id}
        renderInput={(params) => (
          <Tooltip title={tooltip}>
            <TextField
              {...params}
              placeholder={placeholder}
              name={name}
              required={required}
              error={error}
              data-cy={testId || selectTestIds.select}
            />
          </Tooltip>
        )}
      />
      {error && (
        <FormHelperText error sx={{ ml: 2 }} data-testid={`${testId}-${errorTestIds.errorText}`}>
          {errorText}
        </FormHelperText>
      )}
    </div>
  );
};

export default Select;
