import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadcrumbsComponent = ({ pathList }) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {pathList.map((path, index) => (
        <Link
          key={path.route}
          to={path.route}
          style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
        >
          {index === 0 && path.name === 'Home Page' && <HomeIcon fontSize="small" />}
          <Typography color="inherit" component="div">
            {path.name}
          </Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
