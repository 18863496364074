import { useEffect, useState, useContext } from "react";
import { getFirestore, query, where, collection, onSnapshot } from "firebase/firestore";
import app from "../integrations/firebase";
import { decodeEvent } from '../util/eventUtils'
import { DateTime } from 'luxon';
import { NotificationsContext } from '../providers/Notifications';
import { ErrorHandler } from '../util/errorHandler';

const toIsoFormat = (date) => `${date.toISODate()}T${date.toFormat('T')}`;

const useEvents = ({ officeId, fromDate, toDate }) => {
  const [events, setEvents] = useState([]);

  const db = getFirestore(app);

  const notifications = useContext(NotificationsContext);

  const { handleError } = ErrorHandler();

  useEffect(() => {
    const from = DateTime.fromISO(toIsoFormat(fromDate));
    const to = DateTime.fromISO(toIsoFormat(toDate ? toDate : fromDate.plus({ years: 1 })));

    const eventsRef = collection(db, "offices", officeId, "events");
    const queryRef = query(eventsRef, where("ends", ">=", from.toJSDate()));

    const fail = (reason) => { 
      console.error(`Failed to load event, because ${reason}.`); 
    };

    const unsubscribe = onSnapshot(queryRef, 
      (snapshot) => {
        const eventData = snapshot.docs
          .map((doc) => decodeEvent({ ...doc.data(), id: doc.id }, fail))
          .filter((event) => event && event.starts <= to.toJSDate());
        setEvents(eventData);
      },
      (error) => {
        handleError(error, 'unable to fetch events');
      });
      return () => unsubscribe();
  }, [db, fromDate, handleError, notifications, officeId, toDate]);

  return { events };
};

export { useEvents };
