import styled from 'styled-components';
import { Page, Panes, Panel } from '../components/Layout';
import Paper from '@mui/material/Paper';
import { Button, Form, Input, ButtonRow, Select } from '../components/Common';
import { useParams } from 'react-router-dom';
import { useUser, useUsers } from '../hooks';
import { useState, useContext, useEffect } from 'react';
import { NotificationsContext } from '../providers/Notifications';
import BreadcrumbsComponent from '../components/Common/BreadcrumbsComponent';
import { Switch, FormControlLabel } from '@mui/material';
import { useOffices } from '../hooks';
import accountImg from './account.webp';
import { bjss } from '../themes/colours';
import { formatLookup } from '../util/lookups';

const BreadcrumbsContainer = styled.div`
  margin: 25px 0;
`;

const Image = styled.img`
  width: 30%;
  margin: 0 auto 1.5em auto;
`;

const User = styled.span`
  display: flex;
  justify-content: center;
  font-weight: 600;
`;

const Wrapper = styled(Panes)`
  padding: 1.6rem;
`;

const StyledInput = styled(Input)`
  border: none;
  width: 100%;
`;

const StyledPanelLeft = styled(Panes.Left)`
  border-right: 2px solid ${bjss.grey};
  justify-content: center;
  align-items: center;
`;

const Hr = styled.hr`
  width: 100%;
  margin: 1rem 0;
  color: rgb(228, 228, 228);
`;

const StyledSelect = styled(Select)`
  width: 80%
`

const ProfilePage = () => {
  const notifications = useContext(NotificationsContext);
  const { officeId } = useParams();
  const offices = useOffices();
  const user = useUser();
  const { updateUser } = useUsers();
  
  const [darkTheme, setDarkTheme] = useState(false);
  const [selectedOfficeId, setSelectedOfficeId] = useState(user?.officeId || '');

  useEffect(() => {
    const office = offices.find((o) => user.officeId === o.id);
    if (office) {
      setSelectedOfficeId(office.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(offices), user]);

  const handleThemeChange = (event) => setDarkTheme(event.target.checked);

  const onSaveClicked = async () => {
    if (await updateUser({ ...user, officeId: selectedOfficeId })) {
      notifications.enqueue('Profile updated!', 'Your account profile has been updated.');
    }
  };

  return (
    <Page>
      <BreadcrumbsContainer>
        <BreadcrumbsComponent
          pathList={[
            { name: 'Home Page', route: `/${officeId}` },
            { name: 'Profile', route: `/${officeId}/account` },
          ]}
        />
      </BreadcrumbsContainer>

      <Paper elevation={5}>
        <Wrapper>
          <StyledPanelLeft>
            <Panel>
              <Image alt="Account image" src={accountImg} />
              <User data-cy="account-username">{user.name}</User>
            </Panel>
          </StyledPanelLeft>
          <Panes.Right>
            <Panel>
              <Panel.Title data-cy="account-details-title">
                Personal details
              </Panel.Title>
              <Form data-testid="profile-page-form">
                <Form.Field
                  row
                  label="Name"
                  labelDataCy="account-username-label"
                  style={{
                    borderBottom: '1px solid rgb(49, 181, 149)',
                    width: '100%',
                    borderColor: 'rgb(49, 181, 149)',
                  }}
                >
                  <StyledInput
                    data-testid="account-username-input"
                    data-cy="account-username-input"
                    type="text"
                    readOnly
                    value={user.name || ''}
                  />
                </Form.Field>
                <Form.Row>
                  <Form.Field
                    row
                    label="Email"
                    labelDataCy="account-email-label"
                  >
                    <StyledInput
                      data-testid="account-email-input"
                      data-cy="account-email-input"
                      type="text"
                      readOnly
                      value={user.email || ''}
                    />
                  </Form.Field>
                </Form.Row>
              </Form>
              <Hr />
              <Form data-testid="default-office-form">
                <Form.Row>
                  <Form.Field
                    row
                    label="Default Office"
                    labelDataCy="account-default-office-label"
                  >
                   <StyledSelect
                      options={formatLookup(offices)} 
                      value={selectedOfficeId} 
                      onChange={setSelectedOfficeId}
                    />
                  </Form.Field>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={darkTheme}
                        onChange={handleThemeChange}
                        name="darkTheme"
                        color="primary"
                      />
                    }
                    label="Dark Theme"
                  />
                </Form.Row>
              </Form>
              <ButtonRow right>
                <Button
                  data-testid="default-office-form-save-button"
                  bigger
                  onClick={onSaveClicked}
                  dataCy="account-save-button"
                  disabled={!selectedOfficeId}
                >
                  Save
                </Button>
              </ButtonRow>
            </Panel>
          </Panes.Right>
        </Wrapper>
      </Paper>
    </Page>
  );
};

export { ProfilePage };
