import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    useTheme,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    FormControl,
    Select,
    MenuItem
} from '@mui/material';
import { useOffices } from '../../hooks';

export default function OfficeSelectorDialog() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { officeId } = useParams();
    const offices = useOffices();
    const [office, setOffice] = React.useState(officeId);
    const [open, setOpen] = React.useState(false);

    //event hanlders
    const handleOpenDialog = () => {
        setOpen(true);
    }
    const handleCloseDialog = (event, reason) => {
        if (reason !== "backdropClick") setOpen(false);
    }
    const handleSelectOffice = (event) => {
        setOffice(event.target.value);
    }
    const handleCancelDialog = (event) => {
        setOffice(officeId);
        handleCloseDialog(event);
    }
    const handleConfirmOffice = (event) => {
        navigate(`/${office}`);
    }

    const styles = {
        button: {
            color: theme.palette.secondary.contrastText,
            textTransform: "capitalize",
            '@media (max-width:900px)': {
                color: "rgb(200, 200, 200)"
            }
        }
    }

    return (
        <>
            <Button
                onClick={handleOpenDialog}
                sx={styles.button}>
                change office
            </Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleCloseDialog}>
                <DialogTitle>Select Office</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Select the office that you want to see the events.
                    </Typography>
                    <FormControl>
                        <Select
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            value={office}
                            onChange={handleSelectOffice}
                        >
                            {
                                offices.map((office, key) => {
                                    return <MenuItem key={key} value={office.id}>{office.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleCancelDialog}>Cancel</Button>
                    <Button variant="contained" onClick={handleConfirmOffice}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
