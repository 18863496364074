import { styled } from 'styled-components';
import { ImageIcon } from '../Icons';
import { useTheme } from '@mui/material';
import { Diversity3Outlined as Diversity3OutlinedIcon } from '@mui/icons-material';
import { HubOutlined as HubOutlinedIcon } from '@mui/icons-material';
import { BusinessCenterOutlined as BusinessCenterOutlinedIcon } from '@mui/icons-material';
import { CelebrationOutlined as CelebrationOutlinedIcon } from '@mui/icons-material';

const ImageContainer = styled.div`
  container-type: size;
  background-color: ${(props) => props.$backgroundcolour};
  height: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;

  .icon--image {
    fill: ${(props) => props.$iconcolour};
  }
`;

const Image = styled.img`
  height: 100cqh;
  width: 100%;
  object-fit: cover;
`;

const StyledIcon = styled.div`
  transform: scale(2.5);
`;

const EventImage = ({ src = undefined, icon = undefined }) => {
  const theme = useTheme();

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'Diversity3Outlined':
        return (<Diversity3OutlinedIcon/>);
      case 'HubOutlined':
        return (<HubOutlinedIcon/>);
      case 'BusinessCenterOutlined':
        return (<BusinessCenterOutlinedIcon/>);
      case 'CelebrationOutlined':
        return (<CelebrationOutlinedIcon/>);
      default:
        return <ImageIcon />
    }
  }

  return (
    <ImageContainer
      $backgroundcolour={theme.palette.placeholderImage.background}
      $iconcolour={theme.palette.placeholderImage.icon}
    >
      {src ? <Image src={src} alt="event image" /> : <StyledIcon>{ getIcon(icon) }</StyledIcon>}
    </ImageContainer>
  );
};

export default EventImage;
