import classnames from "classnames";
import { Typography } from "@mui/material";
import headingClasses, { headingDataTestIds } from "./classes";

const Heading = ({ variant = "h1", children, className = "", "data-testid": testId = "" }) => {
  if (variant === "h2") {
    return (
      <Typography
        id={testId}
        variant="h2"
        noWrap
        sx={{ mb: 2 }}
        fontSize="24px"
        lineHeight="24px"
        letterSpacing="0.25px"
        fontFamily="Catamaran"
        fontWeight={400}
        className={classnames(headingClasses.heading2, className)}
        data-testid={testId || headingDataTestIds.heading2}
      >
        {children}
      </Typography>
    );
  }

  return (
    <Typography
      id={testId}
      variant="h1"
      noWrap
      sx={{ mb: 3 }}
      fontSize="28px"
      lineHeight="32px"
      letterSpacing="0.25px"
      fontFamily="Catamaran"
      fontWeight={400}
      className={classnames(headingClasses.heading1, className)}
      data-testid={testId || headingDataTestIds.heading1}
    >
      {children}
    </Typography>
  );
};

export default Heading;
