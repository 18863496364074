import { createTheme } from '@mui/material/styles';
import { bjss, layout, text } from './colours';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: bjss.green,
      contrastText: bjss.white,
    },
    secondary: {
      main: bjss.navy,
      contrastText: bjss.white,
    },
    success: {
      main: bjss.green,
      contrastText: bjss.white,
    },
    error: {
      main: '#D90606',
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    lightGreen: {
      main: '#d6f0ea',
      contrastText: bjss.navy,
    },
    background: {
      main: layout.background,
    },
    placeholderImage: {
      background: '#f5f5f5',
      icon: '#989898',
    },
    lightTextButton: {
      main: '#ffffff',
    },
    // Brand colours.
    bjssNavy: {
      main: bjss.navy,
    },
    bjssWhite: {
      main: bjss.white,
    },
    bjssGrey: {
      main: bjss.grey,
      contrastText: '#ffffff',
    },
    bjssBlue: {
      main: bjss.blue,
    },
    bjssPink: {
      main: bjss.pink,
    },
    bjssGreen: {
      main: bjss.green,
      contrastText: bjss.white,
    },
    bjssPurple: {
      main: bjss.purple,
    },
    bjssTeal: {
      main: bjss.teal,
    },
  },

  typography: {
    fontFamily: '"Catamaran", sans-serif',
    fontSize: 16,

    h1: {
      fontSize: '2.25rem',
      fontWeight: 700,
      color: text.header,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 700,
      color: text.header,
    },
    h3: {
      fontSize: '1.4375rem',
      fontWeight: 700,
      color: text.header,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      color: text.header,
    },
    body1: {
      fontSize: '1rem',
      color: text.primary,
    },
    body2: {
      fontSize: '0.9375rem',
      color: text.secondary,
    },
  },
});
