import { useState } from 'react';
import styled from 'styled-components';
import { UserSearch } from './UserSearch';
import { UsersTable } from './UsersTable';

const UsersContainer = styled.div`
  width: 100%;
`;

const Users = ({ loggedInUser, officeId }) => {
  const [filteredUsers, setFilteredUsers] = useState([]);

  return (
    <UsersContainer data-testid="users-container">
      <UserSearch
        officeId={officeId} 
        loggedInUser={loggedInUser} 
        onSearchResultsChanged={setFilteredUsers}
      />
      <UsersTable 
        loggedInUser={loggedInUser} 
        users={filteredUsers} 
      />
    </UsersContainer>
  )
}

export { Users };