import { useState, useContext, useEffect } from "react";
import app from "../integrations/firebase";
import { getFirestore, doc, onSnapshot  } from "firebase/firestore";

import { Context } from "../providers/AuthProvider";

const useUser = () => {
  const { state: { currentUser } = {} } = useContext(Context);
  const [ user, setUser ] = useState({
      id: currentUser?.uid,
      name: currentUser?.displayName,
      email: currentUser?.email,
      officeId: currentUser?.officeId, // fetched early by AuthProvider
      role: currentUser?.role,
  });

  useEffect( () => {
    const authFields = {
      id: currentUser?.uid,
      name: currentUser?.displayName,
      email: currentUser?.email,
      officeId: currentUser?.officeId, // fetched early by AuthProvider
      role: currentUser?.role,
    };
    if (currentUser?.uid) {
      const db = getFirestore(app);
      const userRef = doc(db, "users", currentUser.uid);
      const unsubscribe = onSnapshot(userRef, doc => {
        if(doc.exists()) {
          setUser( {
            ...doc.data(),
            ...authFields,
            officeId: doc.data().officeId, // override early fetch now
            role: doc.data().role 
          } );
        } else {
          setUser(authFields);
        }
      });
      return () => unsubscribe();
    } else {
      setUser(authFields);
    }
  }, [currentUser]);

  return user;
};

export default useUser;
