import Event from './Event';
import { useEvents } from '../../hooks';
import { useUser } from '../../hooks';
import { styled } from 'styled-components';
import { Typography } from '@mui/material';
import { Paper } from '@mui/material';

const NoEventsMessage = styled.main``;

const truncateEventDescription = (event) => {
  const { description } = event;
  const maxDescriptionLength = 130;
  const truncatedDescription = description.length > maxDescriptionLength
      ? `${description.substring(0, maxDescriptionLength)}...`
      : description;
  return { ...event, description: truncatedDescription };
}

const Events = ({
  officeId,
  filterSubscriptions,
  showPeriodFrom,
  showPeriodTill,
  enableButtons,
}) => {
  const { events } = useEvents({ officeId, fromDate: showPeriodFrom, toDate: showPeriodTill });

  const { id: userId } = useUser();

  const filteredSubscribedEvents = events.filter((event) => event.subscribers?.includes(userId));

  const displayedEvents = filterSubscriptions
    ? filteredSubscribedEvents
    : events;

  return displayedEvents.length ? (
    displayedEvents
      .sort((a, b) => (a.starts > b.starts ? 1 : -1))
      .map(truncateEventDescription)
      .map((event, key) => (
        <Paper key={key} elevation={5} style={{ marginBottom: '2.5rem', overflow: 'hidden' }}>
          <Event key={`event-${key}`} data={event} enableButtons={enableButtons}/>
        </Paper>
      ))
  ) : (
    <NoEventsMessage>
      <Typography variant="h4" data-cy="no-events-message">
        No events on these day(s).
      </Typography>
    </NoEventsMessage>
  );
};

export default Events;
