import { Actions } from ".";

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    // Announcements have been parked.  2023-03-24
    //case Actions.UpdateAnnouncements:
    //  return { ...state, announcements: payload };
    case Actions.UpdateEvents:
      return { ...state, events: payload };
    case Actions.UpdateOffice:
      return { ...state, office: payload };
    case Actions.UpdateFilter:
      return { ...state, filter: payload};
    default:
      return state;
  }
};

export default reducer;
