import { createContext, useEffect, useReducer, useState } from "react";
import { Outlet } from 'react-router-dom';
import { getFirestore, doc, getDoc, } from "firebase/firestore";
import { useUser } from "../../hooks";
import Actions from "./actions";
import reducer from "./reducer";
import app from "../../integrations/firebase";
import { NoAccess, Spinner } from "../../components/Common";
import { useParams } from 'react-router-dom';
import { Header } from '../../components/Layout/HeaderMUI';

const initialState = {
  announcements: [],
  events: [],
  office: {},
  filter: "week",
};

export const Context = createContext(initialState);

const Provider = ({ children }) => {
  const [hasResponse, setHasResponse] = useState(false);
  const [error, setError] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const loggedInUser = useUser();

  const { id: userId } = loggedInUser;

  const { officeId } = useParams() || {};

  useEffect(() => {
    const dispatchAction = (type, payload) => dispatch({ type, payload });

    if (!officeId) {
      setError("No office");
      setHasResponse(true);
      return;
    }

    const loadOffice = async () => {
      const db = getFirestore(app);

      try {
        const officeDoc = await getDoc(doc(db, "offices", officeId));
        if( !officeDoc.exists() ) {
          throw new Error(
            `Office not found: '${officeId}'`
          );
        }
        const office = {
          ...officeDoc.data(),
          id: officeDoc.id,
        }

        // const backgroundsSnapshot = await getDocs(
        //   collection(db, "offices", office.id, "backgrounds")
        // );

        // const backgrounds = backgroundsSnapshot.docs.map((doc) => ({
        //   ...doc.data(),
        //   id: doc.id,
        // }));

        dispatchAction(Actions.UpdateOffice, {
          ...office,
          //backgrounds,
        });
      } catch (err) {
        setError(err.toString());
      } finally {
        setHasResponse(true);
      }
    };

    loadOffice();
  }, [officeId, userId]);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {hasResponse ? (
        error ? (
          <NoAccess>{error}</NoAccess>
        ) : (
          <>
            <Header loggedInUser={loggedInUser}/>
            <Outlet />
          </>
        )
      ) : (
        <Spinner />
      )}
    </Context.Provider>
  );
};

export default Provider;
