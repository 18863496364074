import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import app from "../integrations/firebase";
import { useUsers } from './useUsers';
import { UserRoles } from '../constants';

const signInAndGetUserCredentials = async () => {
  const auth = getAuth(app);
  const provider = new OAuthProvider("microsoft.com");

  provider.setCustomParameters({
    allow_signup: "false",
    tenant: "bjss.com"
  });

  try {
    return await signInWithPopup(auth, provider);
  } catch (err) {
    console.warn(err);
    return null;
  }
};

const useAuthentication = () => {
  const { createLocalUser } = useUsers();

  const signIn = async () => {
    const userCredentials = await signInAndGetUserCredentials();
    if (userCredentials) {
      const { uid, displayName, email } = userCredentials.user;
      createLocalUser({
        id: uid,
        name: displayName,
        email,
        role: UserRoles.Staff,
        lastLoggedin: new Date().toISOString(),
      });
    }
  }
  return { signIn };
};

export default useAuthentication;
