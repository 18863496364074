import styled, { css } from 'styled-components';
import { useContext, forwardRef } from 'react';
import { TooltipContext } from '../../providers/Tooltips';

const StyledButton = styled.button((props) => {
  const {
    image,
    rightImg,
    invert,
    bigger,
    severe,
    clear,
    flex,
    invalid,
    disabled,
    readOnly,
  } = props;

  const transparent = clear || severe;
  const fg = invert ? 'white' : 'black';
  const bg = invert ? 'black' : 'white';
  const border = readOnly ? '#ddd' : invalid && !disabled ? '#ff6566' : 'black';

  return css`
    ${disabled && !readOnly
      ? css`
          opacity: 0.3;
        `
      : null}
    ${flex
      ? css`
          flex: ${flex};
        `
      : null}
    border: 2px ${transparent ? 'transparent' : border} solid;
    border-radius: 0;
    background-color: ${transparent ? 'transparent' : bg};
    ${image
      ? css`
          background-image: url(${image});
        `
      : null}
    background-position: ${rightImg ? 'right 6px' : 'left 14px'} top 50%;
    background-repeat: no-repeat;
    background-size: 24px;
    color: ${severe ? '#ff6566' : fg};
    padding: ${bigger ? '.3em' : '.2em'} ${rightImg ? '.5em' : '.8em'};
    ${image && rightImg
      ? css`
          padding-right: 50px;
        `
      : null}
    ${image && !rightImg
      ? css`
          padding-left: 50px;
        `
      : null}
    ${rightImg
      ? css`
          text-align: left;
        `
      : null}
    font-size: ${bigger ? '1.4rem' : '1.2rem'};
    font-weight: ${bigger ? '600' : '500'};
    letter-spacing: 0.01em;
    outline: 0;
    transition: border-color 0.2s;
    cursor: default !important;
  `;
});

export const Button = forwardRef((props, ref) => {
  const {
    children,
    onClick,
    image,
    invert,
    bigger,
    severe,
    clear,
    rightImg,
    flex,
    error,
    disabled,
    readOnly,
    onBlur,
    dataCy,
  } = props || {};

  const tooltipId = useContext(TooltipContext);
  const disable = disabled || readOnly;

  return (
    <StyledButton
      data-cy={dataCy}
      ref={ref}
      flex={flex}
      onClick={onClick}
      onBlur={onBlur}
      image={!readOnly && image}
      rightImg={rightImg}
      bigger={bigger}
      invert={invert}
      severe={severe}
      clear={clear}
      invalid={!!error}
      disabled={disable}
      readOnly={readOnly}
      data-tooltip-id={tooltipId}
      data-tooltip-content={error}
      data-tooltip-place="bottom"
    >
      {children}
    </StyledButton>
  );
});

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const ButtonContainerGap = styled.div`
  flex: 1;
`;

const isBool = (x) => typeof x == 'boolean' || x === undefined;

// Usage:
// align row with: <ButtonRow left|right|centre><Button /></ButtonRow>
// or align multi: <ButtonRow left={ <Button /> } right={ <Button /> } />
export const ButtonRow = (props) => {
  const { children, left, centre, right } = props;

  const bools = isBool(left) && isBool(centre) && isBool(right);
  const leftKids = bools ? left && children : left;
  const centreKids = bools ? !left && centre && children : centre;
  const rightKids = bools ? !left && !centre && children : right;

  return (
    <ButtonContainer>
      {leftKids}
      <ButtonContainerGap />
      {centreKids}
      <ButtonContainerGap />
      {rightKids}
    </ButtonContainer>
  );
};
