import app from '../integrations/firebase';
import { 
  getFirestore, 
  doc, 
  getDoc, 
  updateDoc, 
  addDoc, 
  collection, 
  deleteDoc 
} from 'firebase/firestore';
import { useCallback, useContext } from 'react';
import { NotificationsContext } from '../providers/Notifications';
import { decodeEvent, encodeEvent } from '../util/eventUtils';
import { ErrorHandler } from '../util/errorHandler';

const useEvent = () => {
  const db = getFirestore(app);

  const notifications = useContext(NotificationsContext);

  const { handleError } = ErrorHandler();

  const onDecodeError = useCallback((reason) => {
    notifications.enqueue('Error: unable to decode event', `This event can not be loaded because ${reason}.`);
  }, [notifications]);

  const getEvent = async (officeId, eventId) => {
    const eventRef = doc(db, 'offices', officeId, 'events', eventId);
    try {
      const docSnapshot = await getDoc(eventRef);
      return docSnapshot.exists() ? 
        decodeEvent({ ...docSnapshot.data(), id: eventId }, onDecodeError) 
        : 
        undefined;
    } catch (error) {
      handleError({ error, errorMessage: 'unable to fetch event' });
    }
    return undefined;
  }

  const createEvent = async (event, officeId) => {
    const db = getFirestore(app);
    const doc = { ...encodeEvent(event), subscribers: [event.creatorId]};
    const eventsCollection = collection(db, "offices", officeId, "events")
    try {
      const eventRef = await addDoc(eventsCollection, doc);
      return eventRef.id;
    }
    catch(error) {
      handleError({ error, errorMessage: 'unable to create event' });
    }
    return false;
  }

  const updateEvent = async (event, officeId, eventId) => {
    const eventRef = doc(db, "offices", officeId, "events", eventId);
    try {
      await updateDoc(eventRef, encodeEvent(event));
      return true;
    }
    catch(error) {
      handleError({ error, errorMessage: 'unable to update event' });
    }
    return false;
  };

  const deleteEvent = async (officeId, eventId) => {
    const db = getFirestore(app);
    const eventRef = doc(db, "offices", officeId, "events", eventId);
    try {
      await deleteDoc(eventRef);
      return true;
    }
    catch(error) {
      handleError({ error, errorMessage: 'unable to delete event' });
    }
    return false;
  };

  return { getEvent, createEvent, updateEvent, deleteEvent };
}
export { useEvent };