import Actions from "./actions";

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.UserSignIn:
      return {
        ...state,
        currentUser: payload,
      };
    case Actions.UserSignOut:
      return {
        ...state,
        currentUser: null,
      };
    default:
      return state;
  }
};

export default reducer;
