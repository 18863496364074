import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';

const TimeSpan = styled.span`
  margin-left: 1em;
`;
const WallboardTimer = () => {
  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTime.minute !== DateTime.now().minute) {
        setCurrentTime(DateTime.now());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTime]);
  return (
    <>
      <TimeSpan data-cy="timespan-date">
        {DateTime.now().toFormat('ccc d LLL')}
      </TimeSpan>
      <TimeSpan data-cy="timespan-hour">
        {DateTime.now().toFormat('T')}
      </TimeSpan>
    </>
  );
};

export default WallboardTimer;
