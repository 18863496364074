import styled from 'styled-components';
import { Page } from '../components/Layout';
import { BreadcrumbsComponent } from '../components/Common';
import { useParams } from 'react-router-dom';
import { useEvent } from '../hooks'
import { useEffect, useState } from 'react';
import Event from '../components/Events/Event';
import QRCode from "react-qr-code";

const BreadcrumbsContainer = styled.div`
  margin: 25px 0;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
`;

const EventDetailsContainer = styled.div`
  width: 60%;
`;

const QRCodeContainer = styled.div`
  width: 40%;
  div {
    width: fit-content;
    margin: auto;
    padding: 5px;
    background: white;
  }
`;

const EventDetailsPage = () => {
  const { officeId, eventId } = useParams();

  const { getEvent } = useEvent();

  const [event, setEvent] = useState();

  const fetchEvent = async () => setEvent(await getEvent(officeId, eventId));

  useEffect(() => {
    fetchEvent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, officeId]);

  return(
    event &&
      <Page>
        <BreadcrumbsContainer>
          <BreadcrumbsComponent pathList={[
              { name: 'Home Page', route: `/${officeId}` },
              { name: 'Event Details', route: `/${officeId}/event/${eventId}` },
            ]}
          />
        </BreadcrumbsContainer>
        <MainContainer>
          <EventDetailsContainer data-testid="event-details-container">
            <Event 
              data={event}
              enableButtons={true}
              onSubscribedToEvent={fetchEvent}
              onUnsubscribedFromEvent={fetchEvent}
            />
          </EventDetailsContainer>
          <QRCodeContainer data-testid="qr-code-container">
            <div>
              <QRCode value={window.location.href}></QRCode>
            </div>
          </QRCodeContainer>
        </MainContainer>
      </Page>
  )
}

export { EventDetailsPage };