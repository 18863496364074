import { useContext } from "react";

import { Context } from "../providers/OfficeProvider";

const useOffice = () => {
  const { state: { office } = {} } = useContext(Context);

  return office;
};

export default useOffice;
