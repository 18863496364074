import { initializeApp, getApp } from "firebase/app";
import { browserPopupRedirectResolver, browserSessionPersistence, connectAuthEmulator, getAuth, initializeAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_APP_ID
};
 
const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
  measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID,
};

const testConfig = {
  apiKey: process.env.REACT_APP_TEST_API_KEY,
  authDomain: process.env.REACT_APP_TEST_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_TEST_DATABASE_URL,
  projectId: process.env.REACT_APP_TEST_PROJECT_ID,
  storageBucket: process.env.REACT_APP_TEST_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_TEST_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_TEST_APP_ID,
  measurementId: process.env.REACT_APP_TEST_MEASUREMENT_ID,
};

const localConfig = {
  apiKey: process.env.REACT_APP_LOCAL_API_KEY,
  authDomain: process.env.REACT_APP_LOCAL_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_LOCAL_DATABASE_URL,
  projectId: process.env.REACT_APP_LOCAL_PROJECT_ID,
  storageBucket: process.env.REACT_APP_LOCAL_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_LOCAL_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_LOCAL_APP_ID,
  measurementId: process.env.REACT_APP_LOCAL_MEASUREMENT_ID,
};

const firestoreSettings = {};
// Pass long polling setting to Firestore when running in Cypress
if (window.Cypress) {
  // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
  firestoreSettings.experimentalForceLongPolling = true;
}

const getConfig = (env) => {
  switch(env) {
    case 'PROD': {
      return prodConfig;
    }
    case 'TEST': {
      return testConfig;
    }
    case 'DEV': {
      return devConfig;
    }
    case 'LOCAL': {
      return localConfig;
    }
    default: {
      return devConfig;
    }
  }
};

export const firebaseConfig = {env: process.env.REACT_APP_ENV_ID, domain: process.env.REACT_APP_DOMAIN}
const app = initializeApp(getConfig(process.env.REACT_APP_ENV_ID));

initializeFirestore(app, firestoreSettings);

if(process.env.REACT_APP_ENV_ID !== 'TEST') {
  initializeAuth(app, {
    persistence: browserSessionPersistence,
    popupRedirectResolver: browserPopupRedirectResolver
  });
}

const db = getFirestore(app);
const functions = getFunctions(getApp());

if (process.env.REACT_APP_ENV_ID === 'LOCAL') {
  console.log("localhost detected!");
  connectAuthEmulator(getAuth(app), 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export default app;
