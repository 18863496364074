import { useState, useEffect, useCallback } from 'react';
import app from '../integrations/firebase';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { ErrorHandler } from '../util/errorHandler';

const useCategories = () => {
  const [categories, setCategories] = useState([]);

  const db = getFirestore(app);

  const { handleError } = ErrorHandler();

  const fetchCategories = useCallback(async () => {
    const categoryList = [];
    try {
      const categoriesSnapshot = await getDocs(collection(db, 'categories'));
      categoriesSnapshot.forEach((doc) => categoryList.push({ ...doc.data(), id: doc.id }));
    } catch (error) {
      handleError(error, 'unable to fetch categories');
    }
    setCategories(categoryList);
  }, [db, handleError]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const getCategoryByName = (categoryName) => {
    return categories.find((c) => categoryName === c.name);
  }

  return { categories, getCategoryByName };
};

export { useCategories };
