const inputClasses = {
  input: "input-field",
  inputLabel: "input-label",
  bottomText: "bottom-text"
}

export const inputTestIds = {
  input: "input-field",
  inputLabel: "input-label",
  bottomText: "bottom-text"
}

export default inputClasses;
