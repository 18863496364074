import { styled } from 'styled-components';
import { Typography, useTheme } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VerifiedIcon from '@mui/icons-material/Verified';

const EventDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const EventTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const EventDescription = styled.div`
  margin-top: 0.25rem;
`;

const Icon = styled.span`
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;

  .MuiSvgIcon-root {
    transform: translateY(-0.06em);
    height: 100%;
    width: 100%;
    fill: ${(props) => props.$colour};
  }
`;

const EventDetails = ({
  who,
  title,
  where,
  when,
  description,
  approved = false,
  finished = false,
  datacyprefix = undefined,
}) => {
  const theme = useTheme();

  return (
    <>
      <EventDetail>
        <Typography
          variant="body2"
          data-cy={`${datacyprefix && `${datacyprefix}-`}event-card-who`}
        >
          {who}
        </Typography>
      </EventDetail>

      <EventTitle>
        {approved && (
          <Icon $colour={theme.palette.success.main}>
            <VerifiedIcon />
          </Icon>
        )}
        <Typography
          variant="h3"
          data-cy={`${datacyprefix && `${datacyprefix}-`}event-card-title`}
        >
          {!finished ? title : `${title} - Finished`}
        </Typography>
      </EventTitle>

      <EventDetail>
        <Icon $colour={theme.palette.bjssPink.main}>
          <LocationOnIcon />
        </Icon>
        <Typography
          variant="body1"
          data-cy={`${datacyprefix && `${datacyprefix}-`}event-card-location`}
        >
          {where}
        </Typography>
      </EventDetail>

      <EventDetail>
        <Icon $colour={theme.typography.body2.color}>
          <CalendarMonthIcon />
        </Icon>
        <Typography
          variant="body2"
          data-cy={`${datacyprefix && `${datacyprefix}-`}event-card-when`}
        >
          {when}
        </Typography>
      </EventDetail>

      <EventDescription>
        <Typography
          data-cy={`${
            datacyprefix && `${datacyprefix}-`
          }event-card-description`}
        >
          {description}
        </Typography>
      </EventDescription>
    </>
  );
};

export default EventDetails;
