import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { getAuth, signOut } from 'firebase/auth';
import { UserRoles } from '../../constants'

const AvatarDropdownMenu = ({ loggedInUser, headerRef }) => {
  const navigate = useNavigate();
  const { officeId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleProfileClick = () => {
    navigate(`/${officeId}/account`);
    handleClose();
  };

  const handleAdminClick = () => {
    navigate(`/${officeId}/admin`);
    handleClose();
  };

  const handleSignOut = () => {
    setShowSignOutModal(true);
    handleClose();
  };

  const handleConfirmSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Sign-out error:', error);
    }
  };

  const handleCancelSignOut = () => {
    setShowSignOutModal(false);
  };

  const isAdminUser = (user) => {
    const { role: loggedInUserRole } = user;
    return [UserRoles.Admin, UserRoles.SuperAdmin].some((role) => loggedInUserRole === role);
  }

  return (
    <div data-cy="user-menu">
      <Avatar
        alt="User Avatar"
        src="/path-to-your-image.jpg"
        aria-controls="avatar-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Menu
        id="avatar-menu"
        anchorEl={headerRef.current}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            marginLeft: isMobile ? '0px' : '-100px',
            width: isMobile ? '100%' : undefined,
          },
        }}
      >
        <MenuItem data-cy="user-menu-profile" onClick={handleProfileClick}>
          <ListItemIcon>
            <ProfileIcon fontSize="small" sx={{ color: '#31b595' }} />
          </ListItemIcon>
          <Typography variant="inherit">Profile</Typography>
        </MenuItem>
        {isAdminUser(loggedInUser) && 
          <MenuItem data-cy="admin-page"  onClick={handleAdminClick}>
            <ListItemIcon>
              <AdminPanelSettingsIcon fontSize='small' sx={{ color: '#31b595' }} />
            </ListItemIcon>
            <Typography variant='inherit'>Admin</Typography>
          </MenuItem>
        }
        <MenuItem data-cy="user-menu-logout" onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" sx={{ color: '#31b595' }} />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>
      <Dialog open={showSignOutModal} onClose={handleCancelSignOut}>
        <DialogTitle>Are you sure you want to logout?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelSignOut} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSignOut} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AvatarDropdownMenu;
