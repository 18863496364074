import styled, { css } from "styled-components";
import { TooltipContext } from '../../providers/Tooltips';
import { useContext } from 'react';

const StyledTextArea = styled.textarea( ({ invalid }) => css`
  border: 2px ${ invalid? '#ff6566' : 'black' } solid;
  color: black;
  background: white;
  outline: 0;
  font-size: 1.2em;
  font-weight: 500;
  transition: border-color .2s;
` );

export const TextArea = props => {
  const {
    value,
    onChange,
    error,
    dataCy,
  } = props;

  const tooltipId = useContext( TooltipContext );

  const changeHandler = e => {
    onChange && onChange( e.target.value );
  }

  return (
    <StyledTextArea data-cy={dataCy} value={value} onChange={changeHandler} invalid={!!error}
                    data-tooltip-id={tooltipId} data-tooltip-content={error} />
  );
}
