import { Page, Panel } from '../components/Layout';
import styled from "styled-components";

const BG = styled.div`
  margin: -2.5em 0 0 -0.8em;;
  padding: 2em 0;
  transform: rotate( -20deg );
  align-items: center;
  justify-content: center;
  overflow: visible;
  height: 0;
  position: absolute;
  color: #383838;
  font-weight: 900;
  font-size: 15em;

  &::after {
    content: '404';
  }
`;

const H1 = styled.h1`
  position: absolute;
`;

const NotFoundPage = () => (
  <Page>
    <Panel limited clear>
      <BG />
      <H1>Not found.</H1>
    </Panel>
  </Page>
);

export default NotFoundPage;
