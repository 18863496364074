import { createContext, useReducer, useState, useEffect } from 'react';

import { Notifications } from '../components/Layout';

export const NotificationsContext = createContext();

const notificationDelay = 10 * 1000;

const reducer = ( state, action ) => {
  switch( action.type ) {

    case 'add': {
      return [
        ...state,
        action.notification,
      ];
    }

    case 'remove': {
      return state.filter( (notification) => notification.id !== action.id );
    }

    default: {}
  }
}

export const NotificationsProvider = ({ children }) => {

  // eslint-disable-next-line no-unused-vars
  const [ timers, setTimers ] = useState([]);
  const [ notifications, dispatch ] = useReducer( reducer, [] );

  const dismiss = ( id ) => {
    dispatch({
      type: 'remove',
      id: id,
    })
  };

  const enqueue = (title, message) => {
    dispatch( {
      type: 'add',
      notification: {
        title: title,
        message: message,
        id: Date.now(),
      },
    } )
  };

  useEffect( () => {
    setTimers( timers => {
      notifications.forEach( notification => {
        const found = timers.filter( timer => timer.id === notification.id );
        if( !found.length ) {
          const interval = setInterval( () => dismiss( notification.id ), notificationDelay );
          timers = [
            ...timers,
            {
              id: notification.id,
              interval: interval,
            },
          ];
        }
      } );
      timers = timers.filter( timer => {
        const found = notifications.filter( notification => notification.id === timer.id );
        if( !found.length ) {
          clearInterval( timer.interval );
        }
        return !!found.length;
      } );
      return timers;
    } )
  }, [ notifications ] )

  const api = {
    enqueue: enqueue,
    dismiss: dismiss,
  };

  return (
    <NotificationsContext.Provider value={api}>
      <Notifications notifications={notifications} />
      {children}
    </NotificationsContext.Provider>
  );
};
