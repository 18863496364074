import { createContext } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const TooltipContext = createContext();

export const TooltipProvider = ({ children }) => {
  const tooltipId = "A715DE53-0738-497A-BB83-1FD02D336E20";
  return (
    <TooltipContext.Provider value={tooltipId}>
      <Tooltip id={tooltipId} />
      {children}
    </TooltipContext.Provider>
  );
};
