const dateClasses = {
  date: "date-field",
  dateLabel: "date-label",
  datePicker: "date-picker",
  dateInput: "date-input",
  clearButton: "date-field-clear-button"
}

export const dateTestIds = {
  date: "date-field",
  dateLabel: "date-label",
  datePicker: "date-picker",
  dateInput: "date-input",
  clearButton: "date-field-clear-button"
}

export default dateClasses;
