import styled from 'styled-components';
import { Modal as MUIModal, Paper, Typography } from '@mui/material';
import { Button } from '../Common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const MobileCalendar = ({ children, active = false, onClose }) => {
  return (
    <MUIModal
      open={active}
      onClose={onClose}
      style={{ display: 'grid', placeItems: 'center', padding: '1rem' }}
    >
      <Container>
        <Typography variant="h2" color="white" align="center">
          Date selection
        </Typography>

        <Paper elevation={5} style={{ padding: '0.5rem' }}>
          {children}
        </Paper>

        <Buttons>
          <Button
            type="text"
            color="lightTextButton"
            onClick={onClose}
            fullWidth
          >
            Cancel
          </Button>
        </Buttons>
      </Container>
    </MUIModal>
  );
};

export default MobileCalendar;
