import styled from "styled-components";

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  color: #fff;
  font-size: 1.5rem;
  margin: 25%;
  min-width: 400px;
`;

const NoAccess = ({ children }) => (
  <OuterContainer>
    <InnerContainer>{children}</InnerContainer>
  </OuterContainer>
);

export default NoAccess;
