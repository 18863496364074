import { DateTime } from 'luxon';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { NotificationsContext } from '../../providers/Notifications';
import {
  Paper,
  Box
} from '@mui/material';
import {
  useUser,
  useOffices,
  useEvent,
  useCategories,
} from '../../hooks';
import { Page, Modal } from '../../components/Layout';
import {
  Button,
  ButtonRow,
  Spinner,
  Input,
  Select,
  Toggle,
  Checkbox,
  DateField,
  Heading,
} from '../../components/Common';
import { EventDef, defaultEvent, defaultErrors } from './EventDef';
import createEditEventClasses, { createEditEventTestIds } from './classes';
import { routes } from '../../util/routes';
import { formatLookup } from '../../util/lookups';
import './index.css';

const CreateEditEventPage = ({ edit = false }) => {
  const { officeId, eventId } = useParams();

  const navigate = useNavigate();

  const notifications = useContext(NotificationsContext);

  const { getEvent, createEvent, updateEvent, deleteEvent } = useEvent();

  const user = useUser();

  const offices = useOffices();

  const officeOptions = formatLookup(offices);

  const { categories } = useCategories();

  const categoryOptions = formatLookup(categories, 'name');

  const [deleteModal, setDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [promptApproval, setPromptApproval] = useState(false);

  const [eventData, setEventData] = useState(defaultEvent);

  const [eventErrors, setEventErrors] = useState(defaultErrors);

  const isMultiDay = (starts, ends) => {
    return !!starts 
      && !!ends 
      && Math.abs(DateTime.fromISO(starts).diff(DateTime.fromISO(ends), 'days')?.values?.days) >= 1;
  };

  useEffect(() => {
    if (edit && eventId && officeId) {
      const fetchEvent = async () => {
        const event = await getEvent(officeId, eventId);
        const multiDay = isMultiDay(event.starts, event.ends);
        setEventData({ 
          ...event,
          virtual: event.virtualUrl !== '',
          location: event.virtualUrl !== '' ? event.virtualUrl : event.location,
          starts: DateTime.fromISO(event.starts),
          ends: DateTime.fromISO(event.ends),
          allDay: !multiDay,
          multiDay: multiDay,
          hasApproval: false,
          officeId 
        });
      }
      fetchEvent();
    } else {
      setEventData({ ...defaultEvent, officeId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, eventId, officeId]);

  const resetError = key => setEventErrors({ ...eventErrors, [key]: false });

  const onChangeEvent = (key, value) => {
    setEventData({ ...eventData, [key]: value });
    resetError(key);
  };

  const onChangeAllDayEvent = value => {
    const eventDataClone = { ...eventData };
    eventDataClone[EventDef.allDay] = value;
    eventDataClone[EventDef.multiDay] = false;
    eventDataClone[EventDef.ends] = null;
    setEventData(eventDataClone);
  };

  const onChangeMultiDayEvent = value => {
    const eventDataClone = { ...eventData };
    eventDataClone[EventDef.allDay] = false;
    eventDataClone[EventDef.multiDay] = value;
    setEventData(eventDataClone);
  };

  const onChangeDateFrom = value => {
    const eventDataClone = { ...eventData };
    eventDataClone[EventDef.starts] = value;
    eventDataClone[EventDef.multiDay] = isMultiDay(value, eventData.ends);
    setEventData(eventDataClone);
  };

  const onChangeDateTo = value => {
    const eventDataClone = { ...eventData };
    eventDataClone[EventDef.ends] = value;
    eventDataClone[EventDef.multiDay] = isMultiDay(eventData.starts, value);
    setEventData(eventDataClone);
  };

  const deleteHandler = () => setDeleteModal(true);

  const deleteCancelHandler = () => setDeleteModal(false);

  const deleteConfirmHandler = async () => {
    setDeleteModal(false);
    setLoading(true);
    if (await deleteEvent(officeId, eventId)) {
      notifications.enqueue(`Deleted ${eventData.title}`, 'The event was deleted.');
      navigate(routes.office.getUrl(officeId));
    }
    setLoading(false);
  };

  const discardHandler = () => navigate(routes.office.getUrl(officeId));

  const getStartsErrorMessage = () => {
    if (!eventData.starts) return 'Required.';
    if (!eventData.starts.isValid) return 'Invalid date.';
    if (eventData.allDay && eventData.starts < (DateTime.now().startOf('day'))) return 'Event can\'t be in the past.';
    if (!eventData.allDay && eventData.ends && eventData.ends < eventData.starts) return 'Event ends before it starts.';
    return '';
  };

  const getEndsErrorMessage = () => {
    if (!eventData.allDay && !eventData.ends) return 'Required.';
    if (!eventData.allDay && !eventData.ends.isValid) return 'Invalid date.';
    if (!eventData.allDay && eventData.ends && eventData.ends < DateTime.now()) return 'Event has already ended.';
    return '';
  };

  const validate = () => {
    const errors = {
      title: eventData.title?.trim().length > 0 ? '' : 'Required.',
      officeId: eventData.officeId?.trim().length > 0 ? '' : 'Required.',
      location: eventData.location?.trim().length > 0 ? '' : 'Required.',
      starts: getStartsErrorMessage(),
      ends: getEndsErrorMessage(),
      hosts: eventData.hosts?.length > 0 ? '' : 'Required.',
      organisation: eventData.organisation?.trim().length > 0 ? '' : 'Required.',
    };
    setEventErrors(errors);
    return Object.values(errors).filter(val => val !== '').length === 0;
  };

  const getNeedsApproval = () => {
    const selectedCategory = categories.find((cat) => cat.name === eventData.category);
    return selectedCategory && selectedCategory.needsApproval;
  };

  const formatDateTime = (date, time, timeZone) => {
    // The dates/times used in state are unaware of timezone (they use local
    // timezone), in order to work with components.  Here, we construct DateTime
    // in the timezone provided (i.e., of the office).
    return DateTime.fromObject(
      {
        year: date.year,
        month: date.month,
        day: date.day,
        hour: eventData.allDay ? time.hour : date.hour,
        minute: eventData.allDay ? time.minute : date.minute,
        second: 0,
      },
      {
        zone: timeZone || 'local',
      },
    );
  };

  const saveEvent = async () => {
    const eventOffice = offices.find(o => o.id === eventData.officeId);
    if (!eventOffice) {
      return notifications.enqueue('Unable to edit office details.', 'This event contains an invalid office.');
    }
    const {
      title,
      external,
      virtual,
      location = '',
      starts,
      ends,
      allDay = false,
      multiDay = false,
      hosts,
      organisation,
      category,
      description = '',
      hasApproval = false,
      creator,
      creatorId,
    } = eventData;

    const fields = {
      title,
      external,
      virtualUrl: virtual ? location : '',
      location: !virtual ? location : '',
      starts: formatDateTime(starts,{ hour: 2, minute: 0 }, eventOffice.timeZone),
      ends: !allDay ? 
        formatDateTime(ends,{ hour: 22, minute: 59 }, eventOffice.timeZone) 
        : 
        formatDateTime(starts,{ hour: 22, minute: 59 }, eventOffice.timeZone),
      allDay,
      multiDay,
      hosts,
      organisation,
      category,
      description,
      needsApproval: getNeedsApproval(),
      hasApproval
    }
    setLoading(true);
    if (edit) {
      // update
      const ok = await updateEvent({ creator, creatorId, ...fields }, eventOffice.id, eventId);
      if (ok) {
        notifications.enqueue( 'Event updated!','The event has been saved. Check your mail for an update!');
        navigate(routes.office.getUrl(eventData.officeId));
      }
    } else {
      // create
      const id = await createEvent({ creator: user.name, creatorId: user.id, ...fields }, eventOffice.id);
      if (id) {
        notifications.enqueue(
          'Event created!',
          'The event was created and you have been subscribed to it. Please check your emails!'
        );
        navigate(routes.office.getUrl(eventData.officeId));
      }
    }
    setLoading(false);
  };

  const saveHandler = async () => {
    if (validate()) {
      if (!!eventData.category) {
        const needsApproval = getNeedsApproval();
        if (needsApproval) {
          setPromptApproval(true);
        }
        else {
          await saveEvent();
        }
      } else {
        await saveEvent();
      }
    }
  };

  const Loader = () => (
    <Spinner active={loading} />
  );

  const ApprovalModal = () => (
    <Modal
      active={promptApproval}
      className={createEditEventClasses.needsApprovalModal}
      data-testid={createEditEventTestIds.needsApprovalModal}
    >
      <Modal.Title data-cy='approval-prompt-modal-title'>
        Approval Required
      </Modal.Title>
      <p>
        This event requires approval from your office manager prior to being listed.
      </p>
      <p>
        Please confirm you have approval from your office manager in order to continue.
      </p>
      <Checkbox
        label="I confirm that I have my office manager's approval for this event."
        labelPlacement='end'
        value={eventData.hasApproval}
        onChange={value => onChangeEvent([EventDef.hasApproval], value)}
      />
      <Modal.Footer>
        <ButtonRow centre>
          <Button onClick={() => setPromptApproval(false)} type='secondary'>Cancel</Button>
          <Button onClick={saveEvent} disabled={!eventData.hasApproval}>
            Save Event
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  );

  const DeleteModal = () => (
    <Modal
      active={deleteModal}
      className={createEditEventClasses.deleteModal}
      data-testid={createEditEventTestIds.deleteModal}
    >
      <Modal.Title data-cy='event-modal-title'>
        Are you sure you want to delete this event?
      </Modal.Title>
      <ButtonRow centre>
        <Button
          dataCy='event-modal-cancel-button'
          bigger
          type='secondary'
          onClick={deleteCancelHandler}
        >
          Cancel
        </Button>
        <Button
          dataCy='event-modal-delete-button'
          bigger
          invert
          default
          type='danger'
          onClick={deleteConfirmHandler}
        >
          Delete
        </Button>
      </ButtonRow>
    </Modal>
  );

  return (
    <Page>
      <ApprovalModal />
      <DeleteModal />
      <Loader />
      <Box
        width='100%'
        hight='100%'
        paddingTop='2rem'
      >
        <Heading variant='h1'>
          {edit ? <>Editing <b>{eventData.title}</b> event</> : 'Create event'}
        </Heading>
        <Paper elevation={2} sx={{ padding: '2rem' }}>
          <div className={createEditEventClasses.page} data-testid={createEditEventTestIds.page}>
            <div className={createEditEventClasses.contentContainer}>
              <form>
                <div className={createEditEventClasses.contentLeft}>
                  <Input
                    name={createEditEventTestIds.title}
                    label='Event title'
                    value={eventData[EventDef.title]}
                    onChange={value => onChangeEvent([EventDef.title], value)}
                    placeholder='Event title'
                    required
                    error={!!eventErrors[EventDef.title]}
                    errorText={eventErrors[EventDef.title]}
                    className={createEditEventTestIds.title}
                    data-testid={createEditEventTestIds.title}
                  />
                  <Checkbox
                    name={createEditEventTestIds.external}
                    label='External event'
                    labelPlacement='end'
                    value={eventData[EventDef.external]}
                    error={!!eventErrors[EventDef.external]}
                    errorText={eventErrors[EventDef.external]}
                    onChange={value => onChangeEvent([EventDef.external], value)}
                    className={createEditEventTestIds.external}
                    data-testid={createEditEventTestIds.external}
                  />
                  <Select
                    name={createEditEventTestIds.office}
                    label='Office'
                    value={eventData[EventDef.officeId]}
                    onChange={value => onChangeEvent([EventDef.officeId], value)}
                    placeholder='Office'
                    required
                    options={officeOptions}
                    error={!!eventErrors[EventDef.officeId]}
                    errorText={eventErrors[EventDef.officeId]}
                    className={createEditEventTestIds.office}
                    data-testid={createEditEventTestIds.office}
                    disabled={edit}
                    tooltip={edit && `Field cannot be edited. 
                      If you wish to change the office, please delete event and recreate event with new office details`
                    }
                  />
                  <div className={createEditEventClasses.venueContainer}>
                    <Input
                      name={createEditEventTestIds.venue}
                      label='Venue'
                      value={eventData[EventDef.location]}
                      required
                      onChange={value => onChangeEvent([EventDef.location], value)}
                      placeholder='Venue'
                      error={!!eventErrors[EventDef.location]}
                      errorText={eventErrors[EventDef.location]}
                      className={createEditEventTestIds.venue}
                      data-testid={createEditEventTestIds.venue}
                      bottomText={eventData.virtual && 'Event URL'}
                    />
                    <Toggle
                      name={createEditEventTestIds.virtual}
                      label='Virtual event?'
                      labelPlacement='end'
                      value={eventData[EventDef.virtual]}
                      error={!!eventErrors[EventDef.virtual]}
                      errorText={eventErrors[EventDef.virtual]}
                      onChange={value => onChangeEvent([EventDef.virtual], value)}
                      className={createEditEventTestIds.virtual}
                      data-testid={createEditEventTestIds.virtual}
                    />
                  </div>
                  <div className={createEditEventClasses.dateContainer}>
                    <div className={createEditEventClasses.dateEventDate}>
                      <DateField
                        name={createEditEventTestIds.startDateTime}
                        label='Event start date'
                        type={eventData.allDay ? 'date' : 'datetime'}
                        value={eventData[EventDef.starts]}
                        onChange={value => onChangeDateFrom(value)}
                        required
                        disablePast={eventData.allDay}
                        maxDateTime={!eventData.allDay && eventData.ends}
                        placeholder='Event date'
                        error={!!eventErrors[EventDef.starts]}
                        errorText={eventErrors[EventDef.starts]}
                        className={createEditEventTestIds.startDateTime}
                        data-testid={createEditEventTestIds.startDateTime}
                      />
                      {!eventData.allDay && <DateField
                        name={createEditEventTestIds.endDateTime}
                        label='Event end date'
                        type='datetime'
                        value={eventData[EventDef.ends]}
                        onChange={value => onChangeDateTo(value)}
                        required
                        minDateTime={!eventData.allDay && eventData.starts}
                        placeholder='Event date'
                        error={!!eventErrors[EventDef.ends]}
                        errorText={eventErrors[EventDef.ends]}
                        className={createEditEventTestIds.endDateTime}
                        data-testid={createEditEventTestIds.endDateTime}
                      />}
                    </div>
                    <div className={createEditEventClasses.dateAllMulti}>
                      <Toggle
                        name={createEditEventTestIds.allDay}
                        label='All day event?'
                        labelPlacement='end'
                        value={eventData[EventDef.allDay]}
                        error={!!eventErrors[EventDef.allDay]}
                        errorText={eventErrors[EventDef.allDay]}
                        onChange={value => onChangeAllDayEvent(value)}
                        className={createEditEventTestIds.allDay}
                        data-testid={createEditEventTestIds.allDay}
                      />
                      <Toggle
                        name={createEditEventTestIds.multiDay}
                        label='Multi day event?'
                        labelPlacement='end'
                        disabled
                        value={eventData[EventDef.multiDay]}
                        error={!!eventErrors[EventDef.multiDay]}
                        errorText={eventErrors[EventDef.multiDay]}
                        onChange={value => onChangeMultiDayEvent(value)}
                        className={createEditEventTestIds.multiDay}
                        data-testid={createEditEventTestIds.multiDay}
                      />
                    </div>
                  </div>
                </div>
                <div className={createEditEventClasses.contentRight}>
                  <Input
                    name={createEditEventTestIds.hosts}
                    label='Host(s)'
                    value={eventData[EventDef.hosts]}
                    onChange={value => onChangeEvent([EventDef.hosts], value)}
                    placeholder='Host(s)'
                    error={!!eventErrors[EventDef.hosts]}
                    errorText={eventErrors[EventDef.hosts]}
                    className={createEditEventTestIds.hosts}
                    data-testid={createEditEventTestIds.hosts}
                  />
                  <Input
                    name={createEditEventTestIds.organisation}
                    label='Organisation'
                    value={eventData[EventDef.organisation]}
                    onChange={value => onChangeEvent([EventDef.organisation], value)}
                    placeholder='Organisation'
                    error={!!eventErrors[EventDef.organisation]}
                    errorText={eventErrors[EventDef.organisation]}
                    className={createEditEventTestIds.organisation}
                    data-testid={createEditEventTestIds.organisation}
                  />
                  <Select
                    name={createEditEventTestIds.category}
                    label='Category'
                    value={eventData[EventDef.category]}
                    onChange={value => onChangeEvent([EventDef.category], value)}
                    placeholder='Category'
                    options={categoryOptions}
                    error={!!eventErrors[EventDef.category]}
                    errorText={eventErrors[EventDef.category]}
                    className={createEditEventTestIds.category}
                    data-testid={createEditEventTestIds.category}
                  />
                  <Input
                    name={createEditEventTestIds.description}
                    label='Description'
                    value={eventData[EventDef.description]}
                    multiline
                    multilineLines={4}
                    onChange={value => onChangeEvent([EventDef.description], value)}
                    placeholder='Description'
                    error={!!eventErrors[EventDef.description]}
                    errorText={eventErrors[EventDef.description]}
                    className={createEditEventTestIds.description}
                    data-testid={createEditEventTestIds.description}
                  />
                </div>
              </form>
            </div>
            <ButtonRow end>
              {edit && <Button
                dataCy='event-modal-delete-button'
                bigger
                invert
                default
                type='danger'
                data-testid={createEditEventTestIds.deleteButton}
                onClick={deleteHandler}
              >
                Delete this event
              </Button>}
              <Button
                dataCy='event-modal-cancel-button'
                bigger
                type='secondary'
                data-testid={createEditEventTestIds.discardButton}
                onClick={discardHandler}
              >
                Discard
              </Button>
              <Button
                dataCy='event-modal-save-button'
                bigger
                data-testid={createEditEventTestIds.saveButton}
                onClick={saveHandler}
              >
                Save Changes
              </Button>
            </ButtonRow>
          </div>
        </Paper>
      </Box>
    </Page>
  );
};

export default CreateEditEventPage;
