import styled, { css } from 'styled-components';
import { Page, ScreenHeader } from '../components/Layout';
import { useOffice, useEvents } from '../hooks';
import AliceCarousel from 'react-alice-carousel';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCategories } from '../hooks/useCategories'
import 'react-alice-carousel/lib/alice-carousel.css';
import './carousel.css';
import EventImage from '../components/Events/EventImage'
import WallboardTimer from './WallboardTimer';
import { PopMessage } from '../components/Common';

const SLIDE_TIME_SECS = 10;

const SLIDES_PER_SCREEN = 3;

const makeGroups = (events) => {
  let ret = [];
  for (let i = 0; i < events.length; i++) {
    if (i % SLIDES_PER_SCREEN === 0) ret.push([]);
    ret[ret.length - 1].push(events[i]);
  }
  if (ret.length === 0) ret.push([]);
  while (ret[ret.length - 1].length < SLIDES_PER_SCREEN) {
    ret[ret.length - 1].push(false);
  }
  return ret;
};

const Today = styled.span`
  background: #ef668f;
  color: white;
  font-size: 1em;
  font-weight: 600;
  border-radius: 30px;
  padding: 0.3em 0.7em;
  margin-right: 0.5em;
`;

const Card = styled.div`
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  color: black;
  height: 140px;
`;

const EmptyCard = styled.div`
  background-color: #393939;
  height: 140px;
`;

const CardPart = styled.div(
  ({ image, flex, alignRight }) => css`
    ${image
      ? css`
          background-color: #d9d9d9;
        `
      : null}
    ${flex
      ? css`
          flex: ${flex};
        `
      : null}
    ${alignRight
      ? css`
          text-align: right;
        `
      : null}
    display: flex;
    flex-direction: column;
    min-width: 150px;
    height: 140px;
    ${image
      ? ''
      : css`
          padding: 0 2em;
        `}
    justify-content: center;

    & img {
      width: 46px;
      height: 38px;
      justify-content: center;
      margin: 0 auto;
    }

    & p {
      font-size: 1em;
      font-weight: 700;
      margin: 0.2em 1em;
      line-height: 1.6em;
    }

    & p:last-of-type {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (max-width: 768px) {
        display: none;
      }
    }
  `,
);

const Who = styled.div`
  color: #444;
  font-size: 0.9em;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 1rem;

  .pop-message .icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.25rem;
  }
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: 600;
  margin-top: 0.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Event = (props) => {
  const { getCategoryByName } = useCategories();

  const {
    officeTimeZone,
    data: {
      starts,
      ends,
      allDay = true,
      hosts = '',
      organisation = '',
      title,
      location = '',
      virtualUrl = '',
      needsApproval,
      hasApproval,
      category
    },
  } = props;

  const from = DateTime.fromISO(starts).setZone(officeTimeZone);
  const till = DateTime.fromISO(ends).setZone(officeTimeZone);

  const getDate = (starts, ends) => {
    const isOneDayEvent = starts.toFormat('d LLLL') === ends.toFormat('d LLLL');
    const isToday =
      DateTime.now().startOf('day').ts >= starts.startOf('day').ts;
    const startDate = starts.weekdayShort + ', ' + starts.toFormat('d LLLL');
    const endDate = ends.weekdayShort + ', ' + ends.toFormat('d LLLL');
    if (isToday)
      return isOneDayEvent ? (
        <Today>Today!</Today>
      ) : (
        <div>
          <Today>Today!</Today> {startDate} - {endDate}
        </div>
      );
    return isOneDayEvent ? startDate : startDate + ' - ' + endDate;
  };
  const date = getDate(starts, ends);

  const time = allDay
    ? 'All day'
    : from.toLocaleString(DateTime.TIME_24_SIMPLE) +
      ' - ' +
      till.toLocaleString(DateTime.TIME_24_SIMPLE);
  const whosep = organisation.length && hosts.length ? ' | ' : '';
  const who = organisation + whosep + hosts;
  const wheresep = location.length && virtualUrl.length ? ' & ' : '';
  const where = location + wheresep + (virtualUrl ? 'Virtually' : '');

  const categoryIcon = getCategoryByName(category)?.icon;
  return (
    <Card>
      <CardPart image>
        <EventImage icon={categoryIcon} />
      </CardPart>
      <CardPart flex="1">
        <Who title={who}>
          {who}
          {needsApproval && hasApproval && (
            <PopMessage messageText="Approved" type="success" />
          )}
        </Who>
        <Title title={title}>{title}</Title>
      </CardPart>
      <CardPart alignRight>
        <p>{date}</p>
        <p>{time}</p>
        <p title={where}>{where}</p>
      </CardPart>
    </Card>
  );
};

const EventGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

const Dot = styled.button(
  ({ selected }) => css`
    padding: 0.2em 0.4em;
    margin: 0;
    border: 0;
    background-color: transparent;
    color: ${selected ? '#f4f4f4' : '#999'};
    font-size: 0.7em;

    &::after {
      content: '⬤';
    }
  `,
);

const WallboardPage = () => {
  const { officeId } = useParams();
  const fromDate = DateTime.now().startOf('day');
  const { events } = useEvents({ officeId, fromDate });
  const [slide, setSlide] = useState(0);

  const groups = makeGroups(events.sort());
  const { name: office = '', timeZone = 'local' } = useOffice() || {};

  const items = groups.map((makeGroups, key) => (
    <EventGroup key={key}>
      {makeGroups.map((event, key) =>
        event === false ? (
          <EmptyCard key={key} />
        ) : (
          <Event key={key} data={event} officeTimeZone={timeZone} />
        ),
      )}
    </EventGroup>
  ));

  const dotRender = ({ isActive }) => {
    return <Dot selected={isActive} />;
  };

  const slideHandler = ({ item, itemsInSlide }) => {
    setSlide(item % itemsInSlide); // wrap slide no. for infinite
  };

  const autoplay = events.length / SLIDES_PER_SCREEN > 1;

  return (
    <Page>
      <ScreenHeader insert={<WallboardTimer />}>
        <span data-cy="title-header">Hello, {office}</span>
      </ScreenHeader>
      <AliceCarousel
        items={items}
        activeIndex={slide}
        onSlideChanged={slideHandler}
        autoPlay={autoplay}
        autoPlayInterval={SLIDE_TIME_SECS * 1000}
        infinite
        keyboardNavigation
        disableButtonsControls
        renderDotsItem={dotRender}
      />
    </Page>
  );
};

export default WallboardPage;
