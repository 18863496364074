import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material';

const LayoutContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.$background};
`;

export const Layout = ({ children }) => {
  const theme = useTheme();

  return (
    <LayoutContainer $background={theme.palette.background.main}>
      <Outlet />
      {children}
    </LayoutContainer>
  );
};