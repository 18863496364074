import styled, { css } from "styled-components";

const PanelContainer = styled.div( ({ page, $clear, $limited }) => css`
  ${ $clear? null : css`background: white;` }
  padding: ${ $clear? '0' : '2em' } 1.5em;
  color: ${ $clear? 'white' : 'black' };
  ${ $clear? css`font-size: 1.4em;` : null }
  font-weight: 500;
  display: flex;
  flex-direction: column;
  ${ page? css`margin-bottom: 2em;` : null }
  ${ $limited? css`
    width: 80%;
    max-width: 500px;
    margin: 20% auto;
  ` : null }
` );

export const Panel = ({ children, page, clear, title, limited, id }) => {
  return (
    <PanelContainer id={id} page={page} $clear={clear} $limited={limited}>
      {children}
    </PanelContainer>
  )
};

const Title = styled.h1`
  font-size: 1.1em;
  font-weight: 700;
  margin: -.3em 0 .8em 0;
`;
Panel.Title = Title;
