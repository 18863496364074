import { useTheme, Typography } from '@mui/material';
import Button from '../components/Common/Button';
import { useAuthentication } from '../hooks';
import { LoginPagesLayout } from '../components/Layout';

const LoginPage = () => {
  const { signIn } = useAuthentication();
  const { palette } = useTheme();

  return (
    <LoginPagesLayout>
      <Typography
        variant="h3"
        align="center"
        sx={{ mb: 5, color: palette.bjssWhite.main }}
      >
        Please sign in with your SSO
      </Typography>

      <Button type="signIn" onClick={signIn} dataCy="login-button" fullWidth>
        Sign In
      </Button>
    </LoginPagesLayout>
  );
};

export default LoginPage;
