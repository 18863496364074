import { useCallback, useContext } from 'react';
import { NotificationsContext } from '../providers/Notifications';

export const ErrorHandler = () => {
  const notifications = useContext(NotificationsContext);

  const handleError = useCallback(({ error, errorMessage }) => {
    notifications.enqueue(`Error: ${errorMessage}.`, 'Please contact administrators or check the logs for details of the error.');
    const { code, name, message } = error;
    console.error(`${errorMessage} - ${name}, ${code}, ${message}`);
  }, [notifications]);

  return { handleError };
}