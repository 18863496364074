const prefix = "create-edit-event-";

const createEditEventClasses = {
  page: `${prefix}page`,
  contentContainer: `${prefix}content-container`,
  contentLeft: `${prefix}content-left`,
  contentRight: `${prefix}content-right`,
  venueContainer: `${prefix}venue-container`,
  dateContainer: `${prefix}date-container`,
  dateEventDate: `${prefix}date-event-date`,
  dateAllMulti: `${prefix}date-all-multi`,
  title: `${prefix}title`,
  external: `${prefix}external`,
  office: `${prefix}office`,
  venue: `${prefix}venue`,
  virtual: `${prefix}virtual`,
  startDateTime: `${prefix}start-date-time`,
  endDateTime: `${prefix}end-date-time`,
  allDay: `${prefix}all-day`,
  multiDay: `${prefix}multi-day`,
  hosts: `${prefix}hosts`,
  organisation: `${prefix}organisation`,
  category: `${prefix}category`,
  description: `${prefix}description`,
  saveButton: `${prefix}save-button`,
  discardButton: `${prefix}discard-button`,
  deleteButton: `${prefix}delete-button`,
  needsApprovalModal: `${prefix}needs-approval-modal`,
  deleteModal: `${prefix}delete-modal`
}

export const createEditEventTestIds = {
  page: `${prefix}page`,
  title: `${prefix}title`,
  external: `${prefix}external`,
  office: `${prefix}office`,
  venue: `${prefix}venue`,
  virtual: `${prefix}virtual`,
  startDateTime: `${prefix}start-date-time`,
  endDateTime: `${prefix}end-date-time`,
  allDay: `${prefix}all-day`,
  multiDay: `${prefix}multi-day`,
  hosts: `${prefix}hosts`,
  organisation: `${prefix}organisation`,
  category: `${prefix}category`,
  description: `${prefix}description`,
  saveButton: `${prefix}save-button`,
  discardButton: `${prefix}discard-button`,
  deleteButton: `${prefix}delete-button`,
  needsApprovalModal: `${prefix}needs-approval-modal`,
  deleteModal: `${prefix}delete-modal`
}

export default createEditEventClasses;
