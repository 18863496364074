const checkboxClasses = {
  toggle: "toggle-field",
  toggleLabel: "toggle-label",
  toggleInput: "toggle-input",
  toggleInputContainer: "toggle-input-container",
  toggleOffText: "toggle-off-text",
  toggleOnText: "toggle-on-text"
}

export const toggleTestIds = {
  toggle: "toggle-field",
  toggleLabel: "toggle-label",
  toggleInput: "toggle-input",
  toggleOffText: "toggle-off-text",
  toggleOnText: "toggle-on-text"
}

export default checkboxClasses;
