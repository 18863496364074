import { useEffect, Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../hooks/useUsers';
import { Context } from "../providers/AuthProvider";

const AuthGuard = ({ component }) => {
  const { state: { currentUser } = {} } = useContext(Context);
  const { getUser } = useUsers();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserOffice = async () => {
      const user = await getUser(currentUser?.uid);
      if (!user?.officeId) {
        navigate('/', { replace: true });
      }
    }
    checkUserOffice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, currentUser, navigate]);

  return <Fragment>{component}</Fragment>
}

export { AuthGuard };