import React, { createContext, useEffect, useReducer, useState } from "react";
import { getAuth, onAuthStateChanged, } from "firebase/auth";

import Actions from "./actions";
import reducer from "./reducer";
import app from "../../integrations/firebase";
import { Spinner } from "../../components/Common";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const initialState = {
  currentUser: {},
};

export const Context = createContext(initialState);

const Provider = ({ children, renderSignIn = () => null }) => {
  const [hasResponse, setHasResponse] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  const { currentUser } = state;

  useEffect(() => {

    const signIn = async user => {
      const { accessToken, uid, displayName, email, } = user;

      // fetch the user's office early
      const db = getFirestore(app);
      const userDoc = await getDoc(doc(db, "users", uid));
      const officeId = userDoc.exists()? userDoc.data().officeId : null;
      const userRole = userDoc.exists()? userDoc.data().role : null;

      dispatch({
        type: Actions.UserSignIn,
        payload: {
          accessToken: accessToken,
          uid: uid,
          officeId: officeId,
          displayName: displayName,
          email: email,
          role: userRole,
        },
      });
      setHasResponse(true);
    }

    const signOut = async () => {
      dispatch({
        type: Actions.UserSignOut,
      });
      setHasResponse(true);
    }

    const auth = getAuth(app);
    return onAuthStateChanged(auth, async (user) => {
      if( user ) {
        signIn( user );
      } else {
        signOut();
      }
    });
  }, []);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {hasResponse ? (
        currentUser ? (
          children
        ) : renderSignIn()
      ) : (
        <Spinner />
      )}
    </Context.Provider>
  );
};

export default Provider;
