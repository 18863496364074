import styled from "styled-components";

export const Panes = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftPane = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
Panes.Left = LeftPane;

const RightPane = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
Panes.Right = RightPane;
