import {
  Paper,
  Container,
  useTheme,
  alpha,
  Box,
  Typography,
} from '@mui/material';

const LoginPagesLayout = ({ children }) => {
  const { palette } = useTheme();

  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      sx={{
        height: '100vh',
        width: '100vw',
        p: 0,
        m: 0,
        backgroundImage: "url('./images/bjss-abstract-bg.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Paper
        sx={{
          height: '100vh',
          width: '100vw',
          background: alpha(palette.bjssNavy.main, 0.65),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            minHeight: '100vh',
          }}
        >
          <Container
            disableGutters={true}
            maxWidth={false}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100%',
              maxWidth: 360,
              p: 1.5,
              m: 0,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                m: 10,
                gap: 3,
              }}
            >
              <img
                src="https://i.ibb.co/P6bmgpf/bjss-logo-25-White-1.png"
                alt="bjss logo"
                width="140"
              />
              <Typography variant="h1" color="white">
                Wallboard
              </Typography>
            </Box>
            {children}
          </Container>
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPagesLayout;
