import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select, Input } from '../../../components/Common';
import { formatLookup } from '../../../util/lookups';
import { useOffices } from '../../../hooks'
import { useUsers } from '../../../hooks'
import { SearchOutlined as SearchOutlinedIcon } from '@mui/icons-material';
import { UserRoles } from '../../../constants';

const UserSearchContainer = styled.div`
  width: 100%;
  height: 110px;
  display: block;
`;

const OfficeSelectContainer = styled.div`
  width: 45%;
  height: inherit;
  float: left;
  .select-field {
    margin: 0;
  }
`;

const UserSearchInputContainer = styled.div`
  width: 45%;
  height: inherit;
  float: right;
  .search-input-container {
    width: 100%;
    position: relative;
    .search-input {
      width: 100%;
      .input-field {
        margin: 0;
      }
    }
    .search-icon {
      top: 50%;
      left: 95%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
`;

const UserSearchResultsOverlay = styled.div`
  position: relative;
  cursor: pointer;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 5px #ddd;
  z-index: 100;
  div {
    width: 100%;
    height: 45px;
    background-color: white;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
  }
  div:hover {
    background-color: #ddd;
    cursor: inherit;
  }
  div:nth-last-child {
    border-bottom: none;
  }
`;

const getSearchableUsers = (loggedInUser, users) => {
  const { id: loggedInUserId, role: loggedInUserRole } = loggedInUser;
  let searchableUsers = users.filter((user) => loggedInUserId !== user.id)
  if (loggedInUserRole !== UserRoles.SuperAdmin) {
    searchableUsers = searchableUsers.filter((user) => UserRoles.SuperAdmin !== user.role);
  }
  return searchableUsers;
}

const executeUserSearch = (searchTerm, users, office) => {
  const filteredData = users.filter((user) => office?.toLowerCase() === user.officeId?.toLowerCase());
  return searchTerm?.length > 0 ? 
    filteredData.filter((user) => user.name?.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) 
    : 
    filteredData;
};

const sortUsers = (users) => (users ?? []).sort((a, b) => a.name?.localeCompare(b.name));

const UserSearch = ({ 
  officeId, 
  loggedInUser, 
  onSearchResultsChanged = () => {} 
}) => {
  const offices = useOffices();

  const [selectedOffice, setSelectedOffice] = useState(officeId);

  const { users } = useUsers(selectedOffice);

  const [searchableUsers, setSearchableUsers] = useState([]);

  const [userSearchText, setUserSearchText] = useState('');

  const [userSearchResult, setUserSearchResult] = useState([]);

  const [searchInputBlurred, setSearchInputBlurred] = useState(false);

  const [showResultsOverlay, setShowResultsOverlay] = useState(false);

  useEffect(() => {
    if (users) {
      setSearchableUsers(getSearchableUsers(loggedInUser, users));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(users)]);

  useEffect(() => {
    const searchResults = executeUserSearch(userSearchText, searchableUsers, selectedOffice);
    onSearchResultsChanged(sortUsers(searchResults));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearchResultsChanged, selectedOffice, userSearchText, JSON.stringify(searchableUsers)]);

  useEffect(() => {
    // the search input is also blurred when user selects an entry from the search results
    // overlay (as we've clicked outside the input), put in a very slight delay in order to 
    // allow the user selection to get through before the overlay disappears
    const timer = setTimeout(() => {
      if (searchInputBlurred) {
        setShowResultsOverlay(false);
      }
      setSearchInputBlurred(false);
    }, 100);
    return () => clearTimeout(timer);
  }, [searchInputBlurred]);

  const onUserSearchInputChanged = (value) => {
    setShowResultsOverlay(true);
    setUserSearchText(value);
    setUserSearchResult(executeUserSearch(value, searchableUsers, selectedOffice));
  };

  const onSearchResultsClick = (user) => {
    setShowResultsOverlay(false);
    setUserSearchText(user.name);
  };

  const { role } = loggedInUser;
  return (
    <UserSearchContainer data-testid="user-search-container">
      <OfficeSelectContainer>
        Select office
        <Select
          data-testid="office-select"
          options={formatLookup(offices)} 
          value={selectedOffice} 
          onChange={setSelectedOffice} 
          disabled={role !== UserRoles.SuperAdmin} 
        />
      </OfficeSelectContainer>
      <UserSearchInputContainer>
        Search user
        <div className="search-input-container">
          <div className="search-input">
            <Input 
              data-testid="user-search-input"
              value={userSearchText} 
              onChange={onUserSearchInputChanged}
              onBlur={() => setSearchInputBlurred(true)}
            />
          </div>
          <div className="search-icon">
            <SearchOutlinedIcon/>
          </div>
        </div>
        {showResultsOverlay && [userSearchText, userSearchResult].every((obj) => obj.length > 0) && 
          <UserSearchResultsOverlay>
            {userSearchResult.map((user) => 
              <div 
                key={user.id} 
                onClick={() => onSearchResultsClick(user)}>
                {user.name}
              </div>
            )}
          </UserSearchResultsOverlay>
        }
      </UserSearchInputContainer>
    </UserSearchContainer>
  )
}

export { UserSearch };