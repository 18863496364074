export const bjss = {
  navy: '#041e42',
  white: '#ffffff',
  grey: '#c8c8c8',
  blue: '#0047bb',
  pink: '#d90699',
  green: '#31b595',
  purple: '#511279',
  teal: '#81bec4',
};

export const text = {
  header: bjss.navy,
  primary: '#000000',
  secondary: '#7A7A7A',
};

export const layout = {
  background: '#f8f8f8',
};
