import { useContext, useState, useEffect } from "react";
import { getFirestore, collection, doc, getDoc, setDoc, query, where, onSnapshot  } from "firebase/firestore";
import app from "../integrations/firebase";
import { NotificationsContext } from '../providers/Notifications';
import { ErrorHandler } from '../util/errorHandler';

const USERS_COLLECTION = "users";

const useUsers = (officeId) => {
  const [users, setUsers] = useState([]);

  const db = getFirestore(app);

  const notifications = useContext(NotificationsContext);

  const { handleError } = ErrorHandler();

  useEffect(() => {
    if (!officeId) {
      return;
    }
    const usersRef = collection(db, USERS_COLLECTION);
    const queryRef = query(usersRef, where("officeId", "==", officeId));
    const unsubscribe = onSnapshot(queryRef, 
      (snapshot) => {
        const filteredUsers = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        setUsers(filteredUsers);
      }, 
      (error) => {
        handleError(error, 'unable to fetch users');
      });
    return () => unsubscribe();
  }, [db, handleError, notifications, officeId]);

  const getUser = async (uid) => {
    const userRef = doc(db, "users", uid);
    try {
      const docSnapshot = await getDoc(userRef);
      return docSnapshot.exists() ? { ...docSnapshot.data(), id: uid } : undefined;
    } catch (error) {
      handleError(error, 'unable to fetch user');
    }
    return undefined;
  };

  const updateUser = async (user) => {
    const { id, ...rest } = user;
    const userRef = doc(db, USERS_COLLECTION, id);
    try {
      await setDoc(userRef, { ...rest }, { merge: true });
    } catch (error) {
      handleError(error, 'unable to update user');
      return false;
    }
    return true;
  }

  const bulkUpdateUsers = async (users) => {
    try {
      await users.forEach(async (user) => {
        if (!await updateUser(user)) {
          return false;
        }
      });
    } catch(error) {
      handleError(error, 'unable to update users');
      return false;
    }
    return true;
  }

  const createLocalUser = async (localUser) => {
    const { id: uid } = localUser;
    const storedUser = await getUser(uid);
    if (!storedUser) {
      const { id, ...rest } = localUser;
      try {
        await setDoc(doc(db, "users", uid), rest);
      } catch (error) {
        handleError(error, 'unable to create user');
      }
    } else {
      const { name, email, lastLoggedin } = localUser;
      const storedUserUpdate = { ...storedUser, name, email, lastLoggedin };
      await updateUser(storedUserUpdate);
    }
  }

  return { users, getUser, createLocalUser, updateUser, bulkUpdateUsers };
}

export { useUsers };