const checkboxClasses = {
  checkbox: "checkbox-field",
  checkboxLabel: "checkbox-label",
  checkboxInput: "checkbox-input"
}

export const checkboxTestIds = {
  checkbox: "checkbox-field",
  checkboxLabel: "checkbox-label",
  checkboxInput: "checkbox-input"
}

export default checkboxClasses;
