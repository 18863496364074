import { styled } from 'styled-components';
import { Button } from '../Common';

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: auto;
  padding-top: 1rem;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;

  &:empty {
    padding-top: 0;
  }

  @container (min-width: 600px) {
    flex-direction: row;
  }
`;

const EventCardButtons = ({
  enableButtons,
  enableEditButtons,
  evId,
  subscribed,
  editHandler,
  unsubscribeHandler,
  subscribeHandler,
  finished,
  datacyprefix = undefined,
}) => {
  return (
    <>
      {enableButtons && (
        <Buttons>
          {enableEditButtons && (
            <Button
              data-testid="event-edit-button"
              icon="edit"
              type="secondary"
              dataCy={`${
                datacyprefix && `${datacyprefix}-`
              }modal-event-card-edit-btn-${evId}`}
              onClick={editHandler}
            >
              Edit
            </Button>
          )}
          {subscribed ? (
            <Button
              data-testid="event-subscribed-button"
              icon="check"
              type="primary"
              dataCy={`${
                datacyprefix && `${datacyprefix}-`
              }modal-event-card-subscribed-btn-${evId}`}
              disabled={finished}
              onClick={unsubscribeHandler}
            >
              Subscribed
            </Button>
          ) : (
            !finished && (
              <Button
                data-testid="event-subscribe-button"
                icon="add"
                type="light"
                dataCy={`${
                  datacyprefix && `${datacyprefix}-`
                }modal-event-card-subscribe-btn-${evId}`}
                onClick={subscribeHandler}
              >
                Subscribe
              </Button>
            )
          )}
        </Buttons>
      )}
    </>
  );
};

export default EventCardButtons;
