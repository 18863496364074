import { Page } from '../components/Layout';
import { BreadcrumbsComponent } from '../components/Common';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const BreadcrumbsContainer = styled.div`
  margin: 25px 0;
`;

const AccessDeniedPage = () => {
  const { officeId } = useParams();
  return (
    <Page>
      <BreadcrumbsContainer>
        <BreadcrumbsComponent pathList={[{ name: 'Home Page', route: `/${officeId}` }]} />
      </BreadcrumbsContainer>
      <h3>Access Denied - you do not have the required role(s) to access this page</h3>
    </Page>
  )
};

export { AccessDeniedPage };
