
import { getFirestore, doc, setDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import app from "../integrations/firebase";
import { ErrorHandler } from '../util/errorHandler';

const useEventSubscription = () => {
  const db = getFirestore(app);

  const { handleError } = ErrorHandler();

  const subscribeEvent = async (officeId, eventId, userId) => {
    const eventRef = doc(db, "offices", officeId, "events", eventId);
    try {
      await setDoc(eventRef, {subscribers: arrayUnion(userId)}, { merge: true });
    }
    catch(error) {
      handleError(error, 'unable to subscribe to event');
      return false;
    }
    return true;
  };

  const unsubscribeEvent = async (officeId, eventId, userId) => {
    const eventRef = doc(db, "offices", officeId, "events", eventId);
    try {
      await setDoc(eventRef, {subscribers: arrayRemove(userId)}, { merge: true });
    }
    catch(error) {
      handleError(error, 'unable to unsubscribe from event');
      return false;
    }
    return true;
  }

  return { subscribeEvent, unsubscribeEvent };
}
export { useEventSubscription };