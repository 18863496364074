import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from 'styled-components';
import { Button, ButtonGroup, useTheme } from '@mui/material';

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiPickersFadeTransitionGroup-root,
  .MuiYearCalendar-root {
    max-height: 17.5rem;
  }
`;

const ButtonContainer = styled.div`
  margin: 0 1rem 1rem;
  padding-top: 1rem;
  border-top: 2px solid ${(props) => props.$bordercolour};
`;

const SelectedMarker = styled.div`
  &.selected {
    .MuiButtonBase-root {
      background-color: ${(props) => props.$colour};
    }
  }
`;

const Calendar = ({
  from = new DateTime(Date.now()),
  onDayChange,
  onTillChange,
  onFilterChange,
  datacyprefix = undefined,
}) => {
  const { palette } = useTheme();
  const [day, setDay] = useState(from);
  const [till, setTill] = useState(0);
  const [filter, setFilter] = useState('day');
  const [selectedDays, setSelectedDays] = useState([]);

  const SelectedDay = ({
    selectedDays,
    day,
    outsideCurrentMonth,
    ...other
  }) => {
    if (!selectedDays) return;

    const isSelected =
      !outsideCurrentMonth && selectedDays.indexOf(day.day) >= 0;

    return (
      <SelectedMarker
        className={isSelected && 'selected'}
        $colour={palette.lightGreen.main}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </SelectedMarker>
    );
  };

  const updateSelectedDays = () => {
    if (!till) {
      setSelectedDays([]);
      return;
    }

    const days = [];

    for (let i = from.day + 1; i <= till; i++) {
      days.push(i);
      setSelectedDays(days);
    }
  };

  const changeMonth = (value) => {
    if (day.month === value.month) {
      updateSelectedDays();
    } else {
      setSelectedDays([]);
    }
  };

  useEffect(() => {
    if (onDayChange) onDayChange(day);
  }, [day]);

  useEffect(() => {
    if (onTillChange) onTillChange(till);
  }, [till]);

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(filter);
    }
  }, [filter, onFilterChange]);

  useEffect(() => {
    updateSelectedDays();
  }, [from, till]);

  useMemo(() => {
    if (filter === 'day') {
      setTill(0);
    } else {
      setTill(day.endOf(filter).day);
    }
  }, [day, filter]);

  return (
    <CalendarContainer>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateCalendar
          value={day}
          onChange={(newValue) => setDay(newValue)}
          onMonthChange={(value) => changeMonth(value)}
          slots={{
            day: SelectedDay,
          }}
          slotProps={{
            day: {
              selectedDays,
            },
          }}
        />
      </LocalizationProvider>
      <ButtonContainer $bordercolour={palette.bjssGrey.main}>
        <ButtonGroup
          variant="contained"
          color="bjssGrey"
          disableElevation
          style={{ width: '100%' }}
        >
          <Button
            fullWidth
            color={filter === 'month' ? 'primary' : 'bjssGrey'}
            onClick={() => setFilter('month')}
            datacy={`${
              datacyprefix && `${datacyprefix}-`
            }calendar-month-button`}
          >
            Month
          </Button>
          <Button
            fullWidth
            color={filter === 'week' ? 'primary' : 'bjssGrey'}
            onClick={() => setFilter('week')}
            datacy={`${datacyprefix && `${datacyprefix}-`}calendar-week-button`}
          >
            Week
          </Button>
          <Button
            fullWidth
            color={filter === 'day' ? 'primary' : 'bjssGrey'}
            onClick={() => setFilter('day')}
            datacy={`${datacyprefix && `${datacyprefix}-`}calendar-day-button`}
          >
            Day
          </Button>
        </ButtonGroup>
      </ButtonContainer>
    </CalendarContainer>
  );
};

export default Calendar;
