import { useState } from 'react';
import { useUser } from '../../hooks'
import { BreadcrumbsComponent } from '../../components/Common';
import { Paper } from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view'
import styled from 'styled-components';
import { Page } from '../../components/Layout';
import { useParams } from 'react-router-dom';
import { 
  ManageAccountsOutlined as ManageAccountsOutlinedIcon, 
  CloudOutlined as CloudOutlinedIcon, 
  PaymentOutlined as PaymentOutlinedIcon
} from '@mui/icons-material';
import { Users } from './Users';
import { UserRoles } from '../../constants';

const BreadcrumbsContainer = styled.div`
  margin: 25px 0;
`;

const AdminContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  margin: 10px 0;
`;

const SidePanel = styled.div`
  width: 20%;
  min-width: 300px;
  margin-right: 40px;
`;

const MainBody = styled.div`
  width: 80%;
  display: inline-flex;
`;

const SidePanelTitle = styled.div`
  margin: auto;
  padding: 20px 10px;
  text-align: center;
`;

const SidePanelBody = styled.div`
  border-top: 2px solid lightgrey;
`;

const StyledParentTreeItem = styled(TreeItem)`
  margin: 15px 20px;
  .MuiTreeItem-label {
    font-weight: bold;
  }
`;

const StyledChildTreeItem = styled(TreeItem)`
  margin: 5px 0;
  div {
    padding: 0;
    .MuiTreeItem-label {
      font-weight: unset;
    }
  }
`;

const NodeIds = {
  Management: 'Management',
  Users: 'Users',
  Products: 'Products',
  Settings: 'Settings',
  Storage: 'Storage',
  Billing: 'Billing'
};

// at the time of writing, only the users admin management option is required hence 
// the visibility settings.
const adminOptions = [
  { 
    nodeId: NodeIds.Management, 
    children: [
      { nodeId: NodeIds.Users, visible: true }, 
      { nodeId: NodeIds.Products, visible: false }, 
      { nodeId: NodeIds.Settings, visible: false }
    ],
    visible: true
  },
  { nodeId: NodeIds.Storage, children: [], visible: false },
  { nodeId: NodeIds.Billing, children: [], visible: false }
];

const userHasAdminPermissions = (user) => {
  const { role } = user;
  return [UserRoles.Admin, UserRoles.SuperAdmin].some((adminRole) => role === adminRole) 
};

const AdminPage = () => {
  const user = useUser();

  const { officeId } = useParams();

  const [expandedManagementOptions, setExpandedManagementOptions] = useState([NodeIds.Management]);

  const [selectedManagementOption, setSelectedManagementOption] = useState(NodeIds.Users);
  
  const handleToggle = (event, nodeIds) => setExpandedManagementOptions(nodeIds);

  const handleSelect = (event, nodeIds) => setSelectedManagementOption(nodeIds);

  const { officeId: userOfficeId } = user;

  const getIcon = (nodeId) => {
    switch (nodeId) {
      case NodeIds.Management:
        return <ManageAccountsOutlinedIcon/>;
      case NodeIds.Storage:
        return <CloudOutlinedIcon/>;
      case NodeIds.Billing:
        return <PaymentOutlinedIcon/>;
      default:
        return null;
    }
  };

  const createChildTreeItem = (option) => {
    const { nodeId, visible } = option;
    if (!visible) {
      return null;
    }
    return (
      <StyledChildTreeItem
        key={nodeId}
        nodeId={nodeId}
        label={nodeId} 
        onClick={() => setSelectedManagementOption(nodeId)}>
      </StyledChildTreeItem>
    );
  };

  const createTreeItem = (option) => {
    const { nodeId, children, visible } = option;
    if (!visible) {
      return null;
    }
    return (
      <StyledParentTreeItem
        key={nodeId}
        nodeId={nodeId} 
        icon={getIcon(nodeId)} 
        label={nodeId}>
        {children.map(createChildTreeItem)}
      </StyledParentTreeItem>
    );
  };

  const switchMainBody = (nodeId) => {
    switch (nodeId) {
      case NodeIds.Users:
        return <Users loggedInUser={user} officeId={userOfficeId}/>;
      default:
        return null;
    }
  };

  return (
    <Page>
      <BreadcrumbsContainer>
        <BreadcrumbsComponent pathList={[
            { name: 'Home Page', route: `/${officeId}` },
            { name: 'Admin', route: `/${officeId}/admin` },
          ]}
        />
      </BreadcrumbsContainer>

      {userHasAdminPermissions(user) ?
        <AdminContainer>
          <SidePanel>
            <Paper style={{ height: "100%" }} elevation={5}>
              <SidePanelTitle>Management</SidePanelTitle>
              <SidePanelBody>
                <TreeView
                  expanded={expandedManagementOptions}
                  selected={[selectedManagementOption]}
                  onNodeToggle={handleToggle}
                  onNodeSelect={handleSelect}
                  sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >
                  { adminOptions.map(createTreeItem) }
                </TreeView>
              </SidePanelBody>
            </Paper>
          </SidePanel>

          <MainBody>{switchMainBody(selectedManagementOption)} </MainBody>
        </AdminContainer>
        :
        <div>Access Denied - you do not have the required role(s) to access the admin page</div>
      }
    </Page>
  );
};

export { UserRoles, AdminPage };