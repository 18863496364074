import { FormLabel, FormHelperText } from "@mui/material";
import classnames from "classnames";
import dateClasses, { dateTestIds } from "./classes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { errorTestIds } from "../../../../util/classes";
import "./index.css";

const DateField = ({
  label,
  name,
  type = "date",
  value = null,
  disabled = false,
  required = false,
  onChange = () => {},
  error = false,
  errorText = "Required.",
  disablePast,
  disableFuture,
  minDate,
  maxDate,
  minDateTime,
  maxDateTime,
  className,
  "data-testid": testId
}) => {
  const slotProps = {
    textField: {
      inputProps: {
        id: testId,
        error: error.toString() || (value === "Invalid date").toString(),
        name,
        style: { width: "100%" },
        'aria-label': "label-project",
        required,
        className: dateClasses.dateInput,
        'data-testid': dateTestIds.dateInput
      }
    }
  };

  const props = {
    value,
    onChange: value => onChange(value),
    disabled,
    disablePast,
    disableFuture,
    minDate: minDate || undefined,
    maxDate: maxDate || undefined,
    'data-cy': testId || dateTestIds.dateInput
  };

  return (
    <div
      className={classnames(dateClasses.date, { disabled, error: error || value === "Invalid date" }, className)}
      data-testid={testId || dateTestIds.date}
      aria-disabled={disabled}
      aria-required={required}
      aria-invalid={error}
    >
      {label && (
        <FormLabel
          component="span"
          className={dateClasses.dateLabel}
          data-testid={dateTestIds.dateLabel}
          required={required}
          disabled={disabled}
          error={error}
          sx={{ mb: 1 }}
        >
          {label}
        </FormLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
        {type === "date" &&
          <DatePicker
            {...props}
            slotProps={slotProps}
          />
        }
        {type === "datetime" &&
          <DateTimePicker
            {...props}
            minDateTime={minDateTime || undefined}
            maxDateTime={maxDateTime || undefined}
            slotProps={slotProps}
          />
        }
      </LocalizationProvider>
      {error && value !== "Invalid date" && (
        <FormHelperText error sx={{ ml: 2 }} data-testid={`${testId}-${errorTestIds.errorText}`}>
          {errorText}
        </FormHelperText>
      )}
      {value === "Invalid date" && (
        <FormHelperText error sx={{ ml: 2 }} data-testid={`${testId}-${errorTestIds.errorText}`}>
          Invalid date.
        </FormHelperText>
      )}
    </div>
  );
};

export default DateField;
