import styled, { css } from 'styled-components';
import { Button, ButtonRow } from '../Common';

import backImg from './back.png';
import logoImg from './logo-white.png';

const Header = styled.div(
  ({ image }) => css`
    width: 100%;
    font-size: 2.5rem;
    font-weight: 800;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 0 0 0.8em 0.1em;
    min-height: 2em;
    ${image
      ? css`
          text-align: center;
        `
      : null}

    & button {
      margin-left: -1em;
    }

    & > img {
      max-height: 55px;
      float: left;
    }
  `,
);

const HeaderInsert = styled.div`
  float: right;
  font-size: 0.65em;
  font-weight: 500;
  letter-spacing: 0;
  text-align: right;
  text-transform: none;
  padding-top: 0.5em;
`;

export const TitleHeader = (props) => {
  const { children, insert } = props;

  return (
    <Header>
      <HeaderInsert>{insert}</HeaderInsert>
      {children}
    </Header>
  );
};

const BackTitle = styled.h1`
  font-size: 2em;
  margin-bottom: 0.6em;
`;

export const BackHeader = (props) => {
  const { onBack, title } = props;

  return (
    <>
      <Header>
        <ButtonRow left>
          <Button
            clear
            invert
            bigger
            image={backImg}
            onClick={onBack}
            dataCy="header-back-button"
          >
            Back
          </Button>
        </ButtonRow>
      </Header>
      {title && <BackTitle data-cy="header-back-title">{title}</BackTitle>}
    </>
  );
};

export const ScreenHeader = (props) => {
  const { children, insert } = props;

  return (
    <Header image={logoImg}>
      <img alt="BJSS Ltd." src={logoImg} />
      <HeaderInsert>{insert}</HeaderInsert>
      {children}
    </Header>
  );
};
