import { useState, useEffect, useContext, useMemo } from 'react';
import { useUsers } from '../../../hooks'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Select, ButtonRow, Button } from '../../../components/Common';
import styled from 'styled-components';
import { NotificationsContext } from '../../../providers/Notifications';
import { UserRoles } from '../../../constants';
import Paper from '@mui/material/Paper';

const MainContainer = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  display: grid;
`;

const UsersTableContainer = styled.div`
  padding: 10px 0;
  display: contents;
`;

const SelectRoleContainer = styled.div`
  .select-field, .MuiInputBase-root {
    margin: 0;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: auto;
`;

// We can allow the users to choose how many rows to view per page e.g. [5, 10, 15]
// For now, the requirement is just a fixed number.
const RowPerPageOptions = [25, 50, 100];

const removeSaveDataProp = (user) => {
  const { saveData, ...rest } = user;
  return { ...rest };
};

const haveDataToSave = (users) => users.some((user) => user.saveData);

const getSelectatbleRoles = (loggedInUser) => {
  const { role: loggedInUserRole } = loggedInUser;
  const selectOptions = Object.values(UserRoles).map((role) => ({ id: role, label: role }));
  return loggedInUserRole === UserRoles.SuperAdmin ? 
    selectOptions 
    :
    selectOptions.filter((role) => UserRoles.SuperAdmin !== role.id);
};

const UsersTable = ({ loggedInUser, users }) => {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(RowPerPageOptions[0]);

  const { bulkUpdateUsers } = useUsers();

  const [unsavedChanges, setUnsavedChanges] = useState([]);

  const notifications = useContext(NotificationsContext);

  useEffect(() => {
    setUnsavedChanges([...users]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(users)]);

  const visibleRows = useMemo(
    () =>
      unsavedChanges.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [page, rowsPerPage, unsavedChanges],
  );

  const onUserRoleChanged = (user, role) => {
    const updatedUsers = unsavedChanges
      .map((unsavedUser) => 
        user.id === unsavedUser.id ? { ...user, role, saveData: true } : unsavedUser);
    setUnsavedChanges(updatedUsers);
  };

  const onPageChange = (event, newPage) => setPage(newPage);

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onCancelClicked = () => setUnsavedChanges([...users]);

  const onSaveClicked = async () => {
    const usersToSave = unsavedChanges
      .filter((user) => user.saveData)
      .map(removeSaveDataProp);
    if (await bulkUpdateUsers(usersToSave)) {
      // update list and remove saveData props
      setUnsavedChanges(unsavedChanges.map(removeSaveDataProp));
      notifications.enqueue('User changes were saved');
    }
  };

  return (
    unsavedChanges.length === 0 ?
      <div>No users found</div>
      :
      <MainContainer>
        <UsersTableContainer data-testid="users-table-container">
          <TableContainer component={Paper}>
            <Table stickyHeader sx={{ minWidth: 500 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">User Name</TableCell>
                  <TableCell align="left">Email Address</TableCell>
                  <TableCell align="left">Office</TableCell>
                  <TableCell align="left">User Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((user) => {
                  const { id, name, email, officeId, role } = user;
                  return (
                    <TableRow 
                      data-testid="users-table-row" 
                      key={id} 
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">{name ?? ''}</TableCell>
                      <TableCell align="left">{email ?? ''}</TableCell>
                      <TableCell align="left">{officeId ?? ''}</TableCell>
                      <TableCell align="left">
                        <SelectRoleContainer>
                          <Select
                            data-testid='role-select'
                            value={role ?? UserRoles.Staff} 
                            options={getSelectatbleRoles(loggedInUser)}
                            onChange={(value) => onUserRoleChanged(user, value)} 
                          />
                        </SelectRoleContainer>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </UsersTableContainer>
        <TablePagination
          rowsPerPageOptions={RowPerPageOptions}
          component="div"
          count={unsavedChanges.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
        <ButtonsContainer>
          <ButtonRow centre>
            <Button data-testid="users-table-cancel-button" onClick={onCancelClicked} type='secondary'>Cancel</Button>
            <Button data-testid="users-table-save-button" onClick={onSaveClicked} disabled={!haveDataToSave(unsavedChanges)}>Save</Button>
          </ButtonRow>
        </ButtonsContainer>
      </MainContainer>
    )
}

export { UsersTable };